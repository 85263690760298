import React, { useState } from "react";
import { TeamRow } from "./TeamRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { useFetch } from "../Util/Hooks/useFetch";
import { MatchList } from "../MatchList/MatchList";
import { config } from "../../config";

const MATCHES_GROUP_URL = (id) =>
  `${config.apiEndpoint}matches?competitionId=${config.competitionId}&seasonYear=${config.year}&phase=TOURNAMENT&groupId=${id}&offset=0&limit=12`;
const GroupMatchList = ({ id }) => {
  const { data, status } = useFetch(MATCHES_GROUP_URL(id));
  if (status === "fetched" && data) {
    return <MatchList matchData={data.reverse()} />;
  } else return null;
};

export const Group = ({ group }) => {
  const groupId = parseInt(group.group.id, 10);
  const [showMatches, setShowMatches] = useState(false);
  return (
    <div>
      <h2>{group.group.metaData.groupName}</h2>
      <table className="table-standings">
        <thead>
          <tr>
            <th>
              <span></span>
            </th>
            <th>
              <span className="label-big">Played</span>
              <span className="label-small">P</span>
            </th>
            <th>
              <span className="label-big">Won</span>
              <span className="label-small">W</span>
            </th>
            <th>
              <span className="label-big">Drawn</span>
              <span className="label-small">D</span>
            </th>
            <th>
              <span className="label-big">Lost</span>
              <span className="label-small">L</span>
            </th>
            <th className="label-big">
              <span>For</span>
            </th>
            <th className="label-big">
              <span>Against</span>
            </th>
            <th className="label-big">
              <span>Goal Difference</span>
            </th>
            <th>
              <span className="label-big">Points</span>
              <span className="label-small">Pts</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {group.items.map((row, index) => (
            <TeamRow key={index} row={row} />
          ))}
        </tbody>
      </table>
      <button
        className="group-details"
        onClick={() => {
          if (!showMatches) {
            setShowMatches(true);
          } else {
            setShowMatches(false);
          }
        }}
      >
        <FontAwesomeIcon icon={!showMatches ? faChevronDown : faChevronUp} />
        <span>Matches</span>
      </button>
      {showMatches && <GroupMatchList id={groupId} />}
    </div>
  );
};
