import { css } from "@emotion/core";
import { theme } from "../../Theme";

export const columns = css`
  div.wp-block-columns {
    @media (min-width: 782px) {
      .wp-block-column:not(:first-child) {
        margin-left: ${theme.blocks.columns.space}%;
      }
    }
    @media (max-width: 782px) {
      .wp-block-column {
        margin-bottom: ${theme.blocks.columns.space}%;
      }
    }
  }
  div.wp-block-columns.no-spacing {
    margin-bottom: 0;
    .wp-block-column {
      margin: 0 !important;
    }
  }
`;
