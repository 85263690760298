import React from "react";
import styled from "@emotion/styled";
import MaxWidth from "../Layout/MaxWidth";
import SEO from "../SEO/SEO";
import { BlockContent } from "../Blocks/BlockContent";
import InnerContent from "../Layout/Content/InnerContent";
import { theme } from "../Theme";
import { PostMeta } from "./PostMeta";
import { config } from "../../config";
import { Breadcrumbs } from "../Layout/Breadcrumbs/Breadcrumbs";

const PostWrapper = styled.article`
  padding-bottom: 3rem;
`;

const PostMaxWidth = styled(MaxWidth)`
  max-width: ${theme.posts.maxWidth};
  .styles-wrapper .block-wrapper {
    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6,
    > p,
    > ul {
      max-width: none;
      padding-left: 0;
      padding-right: 0;
    }
`;

const Header = styled.header`
  margin: 2rem 0 0.5rem;
  h1 {
    margin: 0;
  }
`;

const Post = ({ data }) => {
  const breadcrumbData = [
    {
      text: data.seo.breadcrumbs[0].text,
      url: data.seo.breadcrumbs[0].url,
    },
    {
      text: config.routes.blog.name,
      url: config.routes.blog.uri,
    },
    {
      text: data.categories.nodes[0].name,
      url: config.routes.blog.uri + data.categories.nodes[0].slug,
    },
    {
      text: data.seo.breadcrumbs[1].text,
      url: data.seo.breadcrumbs[1].url,
    },
  ];
  return (
    <PostWrapper>
      <SEO seo={data.seo} author={data.author.node} />
      <PostMaxWidth>
        <Breadcrumbs data={breadcrumbData} />
        <Header>
          <div>{<h1 dangerouslySetInnerHTML={{ __html: data.title }} />}</div>
        </Header>
        <PostMeta post={data} showCat={true} />
        <InnerContent>
          <BlockContent content={data.content} freeWidth={true} />
        </InnerContent>
      </PostMaxWidth>
    </PostWrapper>
  );
};

export default Post;
