import React, { useRef, useEffect } from "react";
import styled from "@emotion/styled";
import RightIcon from "../ui/icons/RightIcon";
import { theme } from "../Theme";

const ScrollSliderWrapper = styled("div")`
  position: relative;
  .slider-track {
    overflow-x: auto;
    /* width */
    ::-webkit-scrollbar {
      height: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #666;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #222;
    }
  }
`;

const SliderItems = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  > .product-card,
  > .item-card {
    min-width: 60%;
    ${theme.above.md} {
      min-width: 38%;
    }
    ${theme.above.lg} {
      min-width: 28%;
    }
    ${theme.above.xl} {
      min-width: 22%;
    }
  }
`;

const ButtonWrapper = styled("button")`
  position: absolute;
  top: calc(50% - 17.5px);
  width: 35px;
  height: 35px;
  box-shadow: 0px 0px 10px #00000059;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  opacity: 0.6;
  ${theme.below.md} {
    display: none;
  }
  &:hover {
    opacity: 0.95;
  }
  svg {
    width: 18px;
    height: 18px;
  }
  &.next {
    right: 0px;
    svg {
    }
  }
  &.prev {
    left: 0px;
    svg {
      transform: rotate(-180deg);
      position: relative;
      left: -1px;
    }
  }
`;

const SliderButton = ({ dir, ...rest }) => (
  <ButtonWrapper className={dir === "next" ? "next" : "prev"} {...rest}>
    <RightIcon />
  </ButtonWrapper>
);

export const ScrollSlider = ({ children, scrollDistance = 600 }) => {
  const sliderRef = useRef(null);

  useEffect(() => {
    return () => {};
  });

  function scrollContainerBy(distance) {
    sliderRef.current.scrollBy({ left: distance, behavior: "smooth" });
  }

  return (
    <ScrollSliderWrapper>
      <>
        <SliderButton
          dir={"prev"}
          onClick={() => {
            scrollContainerBy(-scrollDistance);
          }}
        />
        <SliderButton
          dir={"next"}
          onClick={() => {
            scrollContainerBy(scrollDistance);
          }}
        />
      </>
      <div className="slider-track" ref={sliderRef}>
        <SliderItems>{children}</SliderItems>
      </div>
    </ScrollSliderWrapper>
  );
};

/*
USAGE
<ScrollSlider scrollDistance={600} direction="vertical">
  {products.map((product, index) => (
    <ProductCard
      product={product}
      key={product.id}
    />
  ))}
</ScrollSlider>
*/
