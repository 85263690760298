import React from "react";
import { Switch, Route } from "react-router-dom";
import Header from "./Layout/Header/Header";
import Footer from "./Layout/Footer/Footer";
import Login from "./Login/Login";
import Search from "./Search/Search";
import { global } from "./GlobalStyles/global";
import { Global } from "@emotion/core";
import { config } from "../config";
import Container from "./Layout/Container";
import Content from "./Layout/Content/Content";
import ScrollRestoration from "./Util/ScrollRestoration";
import StartPage from "./StartPage/StartPage";
import BlogPage from "./Blog/BlogPage";
import DynamicRoute from "./DynamicRoute";
import { MediaProvider } from "react-media-universal";
import IconFont from "./Util/Font/IconFont";
import { Match } from "./Match/Match";
import TagManager from "react-gtm-module";

//GTM
if (typeof document !== "undefined") {
  TagManager.initialize({
    gtmId: config.gtmId,
  });
}

if (typeof window !== "undefined") {
  const WebFont = require("webfontloader");
  WebFont.load({
    google: {
      families: ["Oswald:300,400", "Quattrocento Sans:400,700"],
    },
  });
}

export default () => (
  <MediaProvider>
    <Container>
      <Global styles={global} />
      <Header />
      <Content>
        <Switch>
          <Route exact path="/" component={StartPage} />
          <Route exact path="/match/:id" component={Match} />
          <Route exact path={config.routes.blog.uri} component={BlogPage} />
          <Route
            exact
            path={`${config.routes.blog.uri}:cat`}
            component={BlogPage}
          />
          <Route exact path="/login" component={Login} />
          <Route exact path="/search" component={Search} />
          <DynamicRoute />
        </Switch>
      </Content>
      <Footer />
      <ScrollRestoration />
      <IconFont />
    </Container>
  </MediaProvider>
);
