import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";

const RightIcon = ({ className = "", ...rest }) => {
  return (
    <FontAwesomeIcon icon={faChevronRight} {...rest} className={className} />
  );
};

export default RightIcon;
