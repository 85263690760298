import React, { useState } from "react";
import styled from "@emotion/styled";
import { Modal } from "./Modal";

const StyledModal = styled(Modal)`
  .modal-content {
    img {
      object-fit: initial !important;
      width: auto !important;
      height: auto !important;
      max-width: 100%;
      max-height: 100%;
    }
  }
`;

export const ImagePopup = ({ slug, children, ...linkProps }) => {
  const [showImage, setShow] = useState(false);
  return (
    <>
      <a
        href={slug}
        {...linkProps}
        onClick={(e) => {
          setShow(true);
          e.preventDefault();
        }}
      >
        {children}
      </a>
      {showImage && (
        <StyledModal
          close={() => {
            setShow(false);
          }}
        >
          <img src={slug} alt="slug" />
        </StyledModal>
      )}
    </>
  );
};
