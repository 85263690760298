import React from "react";
import styled from "@emotion/styled";
import useFetch from "use-http";
import { MatchList } from "../MatchList/MatchList";
import { config } from "../../config";

const Wrapper = styled.div`
  position: relative;
  > header {
    margin-top: 2rem;
  }
`;

const MATCHES_UPCOMING = `${config.apiEndpoint}matches?&offset=0&limit=100&competitionId=${config.competitionId}&seasonYear=${config.year}&phase=TOURNAMENT&status=UPCOMING`;

export const UpcomingMatches = () => {
  const { data } = useFetch(MATCHES_UPCOMING, {}, []);
  if (data && data.length) {
    const list = data.reverse().splice(0, 16);
    return (
      <Wrapper>
        <header>
          <h3>Kommande matcher</h3>
        </header>
        <MatchList matchData={list} />
      </Wrapper>
    );
  }
  return null;
};
