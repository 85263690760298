import React from "react";
import styled from "@emotion/styled";

const DrawerWrapper = styled.div`
  background: white;
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  height: 100%;
  z-index: 9999;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  transition: all 0.3s;
  &.is-open {
    &.left {
      left: 0 !important;
    }
    &.right {
      right: 0 !important;
    }
  }
`;
const DrawerOverlay = styled.div`
  position: fixed;
  z-index: 9998;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
`;

const Drawer = ({
  right = false,
  size = 320,
  isOpen = false,
  setOpen = () => {},
  children,
}) => {
  let className = "drawer-wrapper ";
  className += right ? "right " : "left ";
  className += isOpen ? "is-open" : "";
  const initialPosition = right
    ? {
        right: `-${size}px`,
      }
    : {
        left: `-${size}px`,
      };
  return (
    <>
      <DrawerWrapper
        className={className}
        style={{ ...initialPosition, width: size }}
      >
        {children}
      </DrawerWrapper>
      {isOpen && (
        <DrawerOverlay
          onClick={() => {
            setOpen(false);
          }}
        />
      )}
    </>
  );
};
export default Drawer;
