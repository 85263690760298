import React, { useState } from "react";
import { Query } from "react-apollo";
import styled from "@emotion/styled";
import { ShutterLink } from "../../../ui/ShutterLink";
//import { theme } from "../../../Theme";
import PlusIcon from "../../../ui/icons/PlusIcon";
import MinusIcon from "../../../ui/icons/MinusIcon";
import menuQuery from "../MenuQuery.gql";
import { theme } from "../../../Theme";
import Logo from "../Logo";
import CrossIcon from "../../../ui/icons/CrossIcon";
import SiteContent from "../../../Util/SiteContent/SiteContent";
import { useActiveMenuItem, menuTreeGen } from "../helpers";

const MobileMenuWrapper = styled("div")`
  padding: 2rem 0;
`;

const MenuHeader = styled.div`
  padding: 0 ${theme.mobileHeader.menu.padding} 2rem;
  display: flex;
  justify-content: space-between;
  img {
    max-width: 100px;
    max-height: 100px;
  }
`;

const CloseButton = styled.button`
  padding: 1rem;
  margin: -1rem;
  svg {
    font-size: 2rem;
  }
`;

const MobileNav = styled.nav`
  display: flex;
  flex-direction: column;
  ul {
    margin-bottom: 1rem;
  }
  ul > li {
    position: relative;
    > button {
      display: flex;
      width: 100%;
      height: 100%;
    }
    button {
      &:hover,
      &:hover a {
        color: ${theme.mobileHeader.nav.hoverColor};
      }
    }
    a,
    span {
      display: block;
    }
    &.active {
    }
    &.selected {
      > button,
      > button a {
        color: ${theme.mobileHeader.nav.hoverColor};
      }
    }
  }

  svg {
    font-size: 1.6rem;
  }

  //LEVEL 1
  > ul {
    > li {
      padding: 0 ${theme.mobileHeader.menu.padding};
      > button {
        line-height: 2em;
        font-size: 1.8rem;
        a,
        span {
          font-family: ${theme.mobileHeader.nav.lv1.fontFamily};
          font-weight: ${theme.mobileHeader.nav.lv1.fontWeight};
          font-style: ${theme.mobileHeader.nav.lv1.fontStyle};
          text-transform: ${theme.mobileHeader.nav.lv1.textTransform};
        }
      }
      //LEVEL 2
      > ul {
        > li {
          > button {
            line-height: 2em;
            font-size: 1.2rem;
          }
          //LEVEL 3
          > ul {
            > li {
            }
          }
        }
      }
    }
  }
`;

const NoSubs = styled.div`
  width: 100%;
  display: flex;
  //justify-content: space-between;
  align-items: center;
  svg {
    margin-left: 1rem;
  }
`;

const MenuContent = styled.div`
  padding: 2rem ${theme.mobileHeader.menu.padding} 2rem;
  p {
    margin-bottom: 1.5rem;
  }
`;

const NavItem = ({ item, setMobileMenu }) => {
  const hasChildren = item.children && item.children.length > 0;

  const url = item.connectedObject ? item.connectedObject.uri : item.url;
  const active = useActiveMenuItem(url);
  const [showSubs, setShowSubs] = useState(active ? true : false);
  return (
    <li className={active ? active : ""}>
      <button>
        {hasChildren ? (
          <NoSubs
            onClick={() => {
              if (showSubs) setShowSubs(false);
              else setShowSubs(true);
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: item.label }} />
            {showSubs ? <MinusIcon /> : <PlusIcon />}
          </NoSubs>
        ) : (
          <ShutterLink
            to={url}
            onClick={() => {
              setMobileMenu(false);
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: item.label }} />
          </ShutterLink>
        )}
      </button>
      {showSubs && hasChildren && (
        <ul>
          <li>
            <button>
              <ShutterLink
                to={item.connectedObject.uri}
                onClick={() => {
                  setMobileMenu(false);
                }}
              >
                <span>{item.label}</span>
              </ShutterLink>
            </button>
          </li>

          <NavList menuList={item.children} setMobileMenu={setMobileMenu} />
        </ul>
      )}
    </li>
  );
};

const NavList = ({ menuList, setMobileMenu }) => {
  if (menuList.length)
    return (
      <>
        {menuList.map((item, index) => (
          <NavItem key={index} item={item} setMobileMenu={setMobileMenu} />
        ))}
      </>
    );
  else return null;
};

export const MobileMenu = ({ setMobileMenu }) => {
  return (
    <MobileMenuWrapper>
      <MenuHeader>
        <Logo />
        <CloseButton
          onClick={() => {
            setMobileMenu(false);
          }}
        >
          <CrossIcon />
        </CloseButton>
      </MenuHeader>
      <MobileNav>
        <Query query={menuQuery} ssr={false}>
          {({ loading, error, data }) => {
            if (loading) return null;
            if (error || !data) return null;
            const menu = data.menus.nodes[0];
            const menuTree = menuTreeGen(menu.menuItems.nodes);
            return (
              <ul>
                <NavList menuList={menuTree} setMobileMenu={setMobileMenu} />
              </ul>
            );
          }}
        </Query>
      </MobileNav>
      <SiteContent id="mobile-menu" ssr={false}>
        {(layout) => (
          <MenuContent
            dangerouslySetInnerHTML={{
              __html: layout.content,
            }}
          />
        )}
      </SiteContent>
    </MobileMenuWrapper>
  );
};
