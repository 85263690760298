import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { theme } from "../Theme";
import { addTime, dateFormat } from "../Util/Helpers";
import { TeamPopup } from "./TeamPopup";
import { useFetch } from "../Util/Hooks/useFetch";

const GameCard = styled.div`
  background: ${theme.colors.primary};
  color: ${theme.colors.white};
  padding-bottom: 2rem;
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${theme.colors.white};
  }
  .game-card-inner {
    text-align: center;
    display: flex;
    flex-direction: column;
    .teams-wrapper {
      margin: 0.5rem 0;
      padding: 0.5rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .teams-seperator {
        font-size: 2rem;
      }
    }
    .stadium-name {
      margin: 0;
    }
    iframe {
      margin: 2rem auto 0;
    }
  }
`;
const TeamWrapper = styled.div`
  width: 40%;
  cursor: pointer;
  ${theme.above.md} {
    width: 30%;
  }
  .team-name {
    font-size: 0.9rem;
    margin: 0 0 0.5rem 0;
  }
  img {
    max-width: 50px;
    ${theme.above.md} {
      max-width: 80px;
    }
  }
`;
const TimeWrapper = styled.div`
  display: flex;
  justify-content: center;
  > *:not(:last-child) {
    margin-right: 5px;
  }
  padding: 5px;
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
`;

const Result = styled.div`
  display: flex;
  align-items: center;
  .seperator {
    margin: 0 5px;
  }
  .score {
    margin: 0;
    font-size: 2rem;
  }
`;

const TimeToBar = styled.div`
  background: ${theme.colors.primaryDark};
  padding: 0.5rem 0;
  transition: all 0.5s;
  &.hide > * {
    opacity: 0;
  }
  h3 {
    color: ${theme.colors.white};
    margin: 0;
    > span {
      &:not(:last-child) {
        margin-right: 10px;
      }
      > span {
        margin-left: 2px;
      }
    }
  }
`;

function countDown(toTime) {
  // The data/time we want to countdown to
  const countDownDate = new Date(toTime).getTime();

  const now = new Date().getTime();
  const timeleft = countDownDate - now;

  // Calculating the days, hours, minutes and seconds left
  const days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

  return {
    days,
    hours,
    minutes,
    seconds,
    timeleft,
  };
}

const Team = ({ team }) => {
  const [showPopup, setPopup] = useState(false);
  return (
    <>
      <TeamWrapper
        className="team-wrapper"
        onClick={() => {
          setPopup(true);
        }}
      >
        <h4 className="team-name">{team.strTeam}</h4>
        {team.strTeamBadge && (
          <img src={team.strTeamBadge} alt={team.strTeam} />
        )}
      </TeamWrapper>
      {showPopup && <TeamPopup team={team} setPopup={setPopup} />}
    </>
  );
};

const TimeTo = ({ time }) => {
  const [timeLeft, setTimeLeft] = useState(countDown(time));
  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(countDown(time));
    }, 1000);
  }, [time, timeLeft.seconds]);
  return (
    <TimeToBar className={timeLeft.timeleft <= 0 && "hide"}>
      <h3>
        <span>
          {timeLeft.days}
          <span>d</span>
        </span>
        <span>
          {timeLeft.hours}
          <span>h</span>
        </span>
        <span>
          {timeLeft.minutes}
          <span>min</span>
        </span>
        <span>
          {timeLeft.seconds}
          <span>s</span>
        </span>
      </h3>
    </TimeToBar>
  );
};

const TEAM_URL = (id) =>
  `https://www.thesportsdb.com/api/v1/json/1/lookupteam.php?id=${id}`;

export const Game = ({ item }) => {
  //GET LOCAL TIME
  const gameTime = item.strTimestamp ? new Date(item.strTimestamp) : false;

  //GET TIME OBJECT
  const time = gameTime ? dateFormat(gameTime) : false;

  const videoId = item.strVideo ? item.strVideo.split("=") : false;

  const videoEmbed =
    videoId && videoId.length > 1
      ? "https://www.youtube.com/embed/" + videoId[1]
      : false;

  const homeData = useFetch(TEAM_URL(item.idHomeTeam));
  const awayData = useFetch(TEAM_URL(item.idAwayTeam));

  const homeTeam = homeData.status === "fetched" ? homeData.data : false;
  const awayTeam = awayData.status === "fetched" ? awayData.data : false;

  return (
    <GameCard className="game-wrapper">
      <div className="game-card-inner">
        <TimeTo time={gameTime} />
        <h2 className="league-name">{item.strLeague}</h2>
        {time && (
          <TimeWrapper>
            <span>{time.day}</span>
            <span>{time.date}</span>
            <span>{time.time}</span>
          </TimeWrapper>
        )}
        <span className="stadium-name">{item.strVenue}</span>
        <div className="teams-wrapper">
          <Team
            team={homeTeam ? homeTeam.teams[0] : { strTeam: item.strHomeTeam }}
          />
          <Result>
            <h4 className="score">{item.intHomeScore}</h4>
            <span className="seperator">-</span>
            <h4 className="score">{item.intAwayScore}</h4>
          </Result>
          <Team
            team={awayTeam ? awayTeam.teams[0] : { strTeam: item.strAwayTeam }}
          />
        </div>
        {videoEmbed && (
          <iframe
            title={item.strVenue}
            width="800"
            height="480"
            src={videoEmbed}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        )}
      </div>
    </GameCard>
  );
};
