import { css } from "@emotion/core";
import { theme } from "../Theme";
import { content } from "./content";
import { typography } from "./typography";
import { forms } from "./forms";
import { icons } from "./icons";
import { media } from "./media";
import { buttons } from "./buttons";

const resets = `
    html,
    body {
      height: 100%;
      font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', 'Arial', 'sans-serif';
    }
    html,
    body,
    #root {
      min-height: 100%;
    }
    #root {
      display: flex;
      flex-direction: column;
    }
    button {
      cursor: pointer;
      padding: 0px;
      border: none;
      font: inherit;
    }
    ol,
    ul,
    h4,
    h3,
    h2,
    h1 {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    p,
    dd {
      margin: 0;
      padding: 0;
    }
    input {
      -webkit-appearance: none;
    }
    .ReactModal__Body--open {
      overflow: hidden;
    }
    .ReactModal__Overlay--after-open {
      overflow: scroll;
    }
    body {
      -webkit-font-smoothing: antialiased;
    }
    /* 
   * apply a natural box layout model to all elements, but allowing components
   * to change */
    html {
      box-sizing: border-box;
    }
    *,
    *:before,
    *:after {
      box-sizing: inherit;
    }
`;

export const global = css`
  ${resets};

  html {
    font-size: ${theme.typography.fontSize};
  }
  body {
    margin: 0;
    font-family: ${theme.typography.fontFamily};
    color: ${theme.colors.font};
    -webkit-font-smoothing: antialiased;
    font-weight: ${theme.typography.fontWeight};
    background: ${theme.colors.white};
    line-height: 1.5;
    letter-spacing: ${theme.typography.letterSpacing};
    visibility: visible;
  }

  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  dd {
    margin: 0;
    padding: 0;
  }

  ${typography}
  ${forms}
  ${content}
  ${icons}
  ${media}
  ${buttons}

`;
