import { css } from "@emotion/core";
import { theme } from "../../Theme";

export const custom = css`
  //MAX-WIDTH
  .max-width {
    margin: 0 auto;
    max-width: ${theme.general.maxWidth};
    padding: 0 ${theme.general.pagePadding.sm};
  }

  //SMALL-WIDTH
  .small-width {
    margin: 0 auto;
    max-width: 960px;
    ${theme.above.lg} {
      padding: 0 ${theme.general.pagePadding.lg};
    }
  }
  .full-screen .small-width {
    padding: 0 ${theme.general.pagePadding.sm};
  }

  .margin-top {
    margin-top: ${theme.general.marginTop.sm}; !important;
    ${theme.above.lg} {
      margin-top: ${theme.general.marginTop.lg} !important;
    }
  }

  .margin-bottom {
    margin-bottom: 3rem !important;
  }

  .padding-side {
    padding: 0 ${theme.general.pagePadding.sm} !important;
    ${theme.above.lg} {
      padding: 0 ${theme.general.pagePadding.lg} !important;
    }
  }

  .padding {
    padding: ${theme.general.pagePadding.sm} !important;
    ${theme.above.lg} {
      padding: ${theme.general.pagePadding.lg} !important;
    }
  }

  .padding-large {
    padding: 4% !important;
    ${theme.above.lg} {
      padding: 8% !important;
    }
  }

  .images-small {
    img {
      width: 180px;
      margin: 10px auto;
    }
  }

  .wp-block-group.flex-text {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .random-background-color {
    .wp-block-media-text__content {
      background: $background-dark-color;
      * {
        color: white !important;
      }
    }
    &:nth-of-type(2n) .wp-block-media-text__content {
      background: $background-dark-color2;
    }
    &:nth-of-type(3n) .wp-block-media-text__content {
      background: $background-dark-color3;
    }
    &:nth-of-type(4n) .wp-block-media-text__content {
      background: $background-dark-color4;
    }
  }

  .background {
    &.grey {
      background: ${theme.colors.greys[1]};
    }
    &.primary {
      background: ${theme.colors.primary};
    }
    &.secondary {
      background: ${theme.colors.secondary};
    }
    &.secondary,
    &.primary {
      color: ${theme.colors.white};
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      a {
        color: ${theme.colors.white};
      }
    }
  }

  .flex-column-2,
  .flex-column-4 {
    > div {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .flex-column-2 {
    > div {
      > div {
        width: 50%;
        ${theme.above.lg} {
          width: 50%;
        }
      }
      .wp-block-cover {
        padding-top: 50%;
        ${theme.above.lg} {
          padding-top: 50%;
        }
        min-height: auto;
        a {
          color: white;
        }
      }
    }
  }
  .flex-column-4 {
    > div {
      > div {
        width: 50%;
        ${theme.above.lg} {
          width: 25%;
        }
      }
      .wp-block-cover {
        padding-top: 50%;
        ${theme.above.lg} {
          padding-top: 25%;
        }
        min-height: auto;
        a {
          color: white;
        }
      }
    }
  }
`;
