import React from "react";
import { Query } from "react-apollo";
import siteContentQuery from "./SiteContentQuery.gql";

const SiteContent = ({ id, children, ssr = true }) => {
  const pathName = "/layout/" + id;

  return (
    <Query
      query={siteContentQuery}
      variables={{
        id: pathName,
      }}
      ssr={ssr}
    >
      {({ loading, error, data }) => {
        if (error) {
          console.log(error);
          console.log("error in SiteContent.js");
        }
        if (data && data.layout && data.layout.content) {
          return children(data.layout);
        } else return null;
      }}
    </Query>
  );
};

export default SiteContent;
