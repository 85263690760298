import React from "react";
import { Query } from "react-apollo";
import Page from "./Page/Page";
import Post from "./Post/Post";
import routeQuery from "./RouteQuery.gql";
import MediaItem from "./MediaItem/MediaItem";
import { LoadingPage } from "./ui/LoadingPage";
import NotFoundPage from "./NotFoundPage";

const DynamicRoute = ({ ...props }) => {
  const { pathname } = props.location;
  return (
    <Query
      query={routeQuery}
      variables={{
        uri: pathname,
      }}
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <LoadingPage />;
        }
        if (error) return <NotFoundPage />;

        if (data && data.nodeByUri) {
          const type = data.nodeByUri.__typename;
          switch (type) {
            case "Page":
              return <Page data={data.nodeByUri} />;
            case "Post":
              return <Post data={data.nodeByUri} />;
            case "MediaItem":
              return <MediaItem data={data.nodeByUri} />;
            default:
              return <NotFoundPage />;
          }
        } else return <NotFoundPage />;
      }}
    </Query>
  );
};

export default DynamicRoute;
