import { css } from "@emotion/core";
import { theme } from "../../Theme";

export const contentStyles = css`
  //IMAGE BOX
  .image-box {
    ${theme.above.lg} {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      &.margin {
        margin: 1rem 0;
      }
      &.border {
        border-top: 1px solid $border-color;
      }
    }
    .image {
      order: 1;
      flex-basis: 50%;
      img,
      video {
        width: 100%;
      }
      &.flip {
        img:last-child {
          display: none;
        }
        &:hover {
          img:first-of-type {
            display: none;
          }
          img:last-child {
            display: block;
          }
        }
      }
    }
    .text {
      order: 2;
      flex-basis: 50%;
    }
    > div.width-25 {
      flex-basis: 25%;
    }
    > div.width-75 {
      flex-basis: 75%;
    }
    > div.width-40 {
      flex-basis: 40%;
    }
    > div.width-60 {
      flex-basis: 60%;
    }
    > div.width-34 {
      flex-basis: 34%;
    }
    > div.width-66 {
      flex-basis: 66%;
    }
    &.image-right {
      .image {
        order: 2;
      }
      .text {
        order: 1;
      }
    }
    &.dark {
      background: ${theme.content.darkBackground};
      .text {
        h1,
        h2,
        h3,
        h4,
        h5,
        p {
          color: ${theme.content.darkColor} !important;
        }
      }
    }
    &.light {
      background: ${theme.content.lightBackground};
    }
  }

  //IMAGE OVERLAY
  .image-overlay {
    position: relative;
    .image {
      width: 100%;
      img,
      video {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    .text {
      position: absolute;
      width: 100%;
      left: 0;
      top: 50%;
      padding: ${theme.content.textPadding.sm};
      max-width: ${theme.general.maxWidth};
      @include transform(translateX(0%) translateY(-50%));
      ${theme.above.md} {
        padding: ${theme.content.textPadding.lg};
        left: 50%;
        @include transform(translateX(-50%) translateY(-50%));
      }
      &.top,
      &.bottom {
        @include transform(translateX(0) translateY(0));
        ${theme.above.md} {
          left: 0;
          @include transform(translateX(0) translateY(0));
        }
      }
      &.top {
        top: 0;
      }
      &.bottom {
        bottom: 0;
        top: auto;
      }
    }
    &.dark {
      .text {
        h1,
        h2,
        h3,
        h4,
        h5,
        p {
          //${theme.above.md} {
          color: ${theme.content.darkColor} !important;
          //}
        }
      }
    }
  }

  .overlay-text {
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    padding: ${theme.content.textPadding.sm};
    max-width: ${theme.general.maxWidth};
    transform: translateX(-50%) translateY(-50%);
    &.white-text {
      * {
        color: white;
      }
    }
    &.flex {
    }
  }

  //FUNKY CLASSES
  .shadow {
    box-shadow: 1px 2px 5px 0 #000;
  }
  div.zoom {
    overflow: hidden;
    img {
      animation: scale 40s linear infinite;
    }
  }

  .grey {
    -webkit-filter: grayscale(100%);
  }
  .sepia {
    -webkit-filter: sepia(100%);
  }

  .greyscale {
    animation: greyscale-fade-in 5s linear;
  }

  .sepiascale {
    animation: sepiascale-fade-in 5s linear;
  }

  //CENTER TEXT
  .center-text {
    padding: ${theme.content.textPadding.sm};
    text-align: center;
    ${theme.above.lg} {
      padding: 100px 20px;
      max-width: 960px;
      margin: 0 auto;
    }
  }

  .text-box {
    padding: ${theme.content.textPadding.sm};
    ${theme.above.lg} {
      padding: ${theme.content.textPadding.lg};
    }
  }

  .center {
    text-align: center;
  }
  .right {
    text-align: right;
  }

  .flex {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    &.right {
      align-items: flex-end;
    }
  }

  //WIDE IMAGE - SCALE WIDE IMAGE IN SMALL SCREENS
  .wide-image {
    ${theme.below.md} {
      width: 100%;
      position: relative;
      height: 500px;
      overflow: hidden;
      img,
      video {
        position: absolute;
        left: 50%;
        top: 50%;
        height: 100%;
        width: auto !important;
        max-width: 9999px;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }
    ${theme.only.sm} {
      height: 400px;
    }
  }

  //STICKY NOTE EFFECT
  .sticky-note {
    text-decoration: none;
    color: #000;
    background: #ffc;
    display: block;
    padding: 1em;
    /* Firefox */
    -moz-box-shadow: 5px 5px 7px rgba(33, 33, 33, 1);
    /* Safari+Chrome */
    -webkit-box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
    /* Opera */
    box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
    -webkit-transform: rotate(-6deg);
    -o-transform: rotate(-6deg);
    -moz-transform: rotate(-6deg);
  }

  //TEXT BACKGROUND
  .text-background {
    position: relative;
    padding: 0 0.2rem;
    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50%;
      content: " ";
      background: ${theme.colors.primary};
      z-index: -1;
    }
    &.color-1:before {
      background: ${theme.colors.primary};
    }
    &.color-2:before {
      background: ${theme.colors.background};
    }
  }

  //HALLMARK BORDER
  .hallmark {
    @include hallmark-border;
    &:after {
      margin-top: 1rem;
    }
    &.center {
      &:after {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  //HOVER EFFECT
  .hover-switch {
    display: none;
  }

  .service-block {
    position: relative;
    padding: 2% 2% 0% 2%;
    text-align: center;
    background: $service-block-background;
    /*&:before {
    content: " ";
    background: lighten(${theme.colors.primary}, 10%);
    position: absolute;
    bottom: 0;
    height: 40%;
    width: 100%;
    left: 0;
  }*/
    .row {
      > div {
        z-index: 1;
        padding: 4%;
        img {
          border-radius: 50%;
        }
      }
    }
  }

  .customer-block {
    overflow: hidden;
    .wp-block-column {
      position: relative;
      margin-bottom: 80px !important;
      ${theme.above.lg} {
        margin-bottom: 140px !important;
      }
      .wp-block-image {
        margin-bottom: 0;
        position: relative;
        padding-top: 100%;
        img {
          position: absolute;
          margin: 0 auto;
          max-width: none;
          max-height: 100%;
          top: 0;
          left: 0;
          height: 100%;
          width: auto;
        }
      }
      .hover-box {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        text-align: center;
        top: 0;
        padding: 20% 5% 20%;
        ${theme.below.md} {
          display: flex;
        }
        h2,
        h3 {
          color: white !important;
          margin: 0 !important;
          text-transform: none;
        }
        h3 {
          font-size: 1.6rem;
          ${theme.above.lg} {
            font-size: 2rem;
          }
        }
        h2 {
          font-size: 2rem;
          ${theme.above.lg} {
            font-size: 4rem;
          }
        }
      }
      &:hover {
        .hover-box {
          display: flex;
        }
      }
      &:after {
        content: " ";
        display: block;
        height: 80px;
        position: absolute;
        bottom: -80px;
        left: 0;
        width: 100%;
        ${theme.above.lg} {
          height: 140px;
          bottom: -140px;
        }
      }
    }
    &.customer-color1 {
      .wp-block-column:first-of-type {
        &:after {
          background: $customer-color1;
        }
        .hover-box {
          background: transparentize($customer-color1, 0.8);
        }
      }
      .wp-block-column:last-child {
        &:after {
          background: $customer-color2;
        }
        .hover-box {
          background: transparentize($customer-color2, 0.8);
        }
      }
    }
    &.customer-color2 {
      .wp-block-column:first-of-type {
        &:after {
          background: $customer-color3;
        }
        .hover-box {
          background: transparentize($customer-color3, 0.8);
        }
      }
      .wp-block-column:last-child {
        &:after {
          background: $customer-color4;
        }
        .hover-box {
          background: transparentize($customer-color4, 0.8);
        }
      }
    }
  }

  .service-boxes {
    margin-top: 4% !important;
    max-width: ${theme.general.maxWidth};
    margin: 0 auto;
    background: ${theme.colors.background};
    padding: 2%;
    ${theme.above.lg} {
      padding: 4%;
    }
    .row {
      > div {
        text-align: center;
        padding: ${theme.blocks.mediaTextPadding.sm} !important;
        > img {
          max-width: 200px;
        }
        * {
          color: white;
        }
        ul {
          list-style-type: none;
          margin: 0;
          li {
            font-style: italic;
          }
        }
      }
      > div:nth-of-type(1) {
        background: ${theme.colors.primary};
      }
      > div:nth-of-type(2) {
        background: ${theme.colors.background};
      }
      > div:nth-of-type(3) {
        background: ${theme.colors.secondary};
      }
      > div:nth-of-type(4) {
        background: ${theme.colors.background2};
      }
    }
  }

  .contact-block {
    margin: 3rem auto;
    padding: 3rem;
    background: ${theme.colors.secondary};
    color: ${theme.colors.font};
    text-align: center;
    max-width: ${theme.posts.maxWidth};
  }
  .full-screen .contact-block {
    padding: 0 $global-padding;
  }

  .contact-box {
    //background: white;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      //font-family: $header-font-family2;
      //font-weight: 400;
      //text-transform: uppercase;
      margin: 1.1em 0 0 0;
    }
  }
  .contact-duo-box {
    display: flex;
    justify-content: space-between;
    max-width: 1000px;
    margin: 0 auto;
    ${theme.below.sm} {
      flex-direction: column;
    }
    ${theme.above.md} {
      padding: 0 1rem;
    }
    h3 {
      margin-top: 0;
    }
    .left-part {
      position: relative;
      //padding: 3rem 2rem;
      //background: $color-3;
      * {
        //color: white;
        &:after {
          border-color: white !important;
        }
      }
      i {
        margin-right: 7px;
      }
      ${theme.above.md} {
        width: 38%;
        padding-right: 2rem;
        border-right: 1px solid $border-color;
        &:after {
          display: block;
          //background: $color-3;
          //content: $font-icon-right;
          //color: white;
          font-size: 20px;
          position: absolute;
          right: -20px;
          top: 20%;
          margin-top: -30px;
          width: 60px;
          height: 60px;
          line-height: 60px;
          text-align: center;
          border-radius: 50%;
          font-family: $font-icon;
        }
      }
      ${theme.above.lg} {
        width: 28%;
      }
      ${theme.below.sm} {
        text-align: center;
        order: 1;
        margin-top: 3rem;
      }
    }
    .right-part {
      background: white;
      ${theme.above.md} {
        width: 55%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      ${theme.above.lg} {
        width: 65%;
      }
      .wpforms-form,
      h3 {
        ${theme.above.md} {
          width: 100%;
        }
        input {
          max-width: 100% !important;
        }
      }
      .wpforms-container {
        margin: 0 0;
      }
      .wpforms-form {
        .wpforms-field-label {
          display: block;
          font-weight: 400;
          font-size: 1rem;
          float: none;
          line-height: 1.3;
          margin: 0 0 4px 0;
          padding: 0;
        }
        .wpforms-field {
          padding: 3px 0;
        }
        button[type="submit"] {
          @include button(primary);
          float: right;
        }
      }
      h3 {
        ${theme.only.sm} {
          margin-top: 3rem;
        }
        margin-bottom: 1rem;
      }
    }
  }

  .about-us {
    .wp-block-column {
      background: white;
      padding-bottom: 5rem;
      > * {
        padding: 0 5rem;
        @include xsmall-only {
          padding: 0 2rem;
        }
      }
      .hallmark {
        margin: 3rem 0;
      }
      .wp-block-image {
        padding: 4rem 0;
        background: $color-3;
        margin-bottom: 3rem;
        img {
          border-radius: 50%;
        }
      }
    }
  }

  .chat-activate {
    background: ${theme.colors.primary};
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    i {
      line-height: 40px;
      font-size: 1.4rem;
      color: white;
    }
  }

  .header-row {
    background: ${theme.startPage.headerRow.background};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 1rem;
    width: 100%;
    position: relative;
    ${theme.below.md} {
      flex-direction: column;
    }
    * {
      color: ${theme.startPage.headerRow.color};
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      margin: 0;
    }
    a.text-button {
      display: flex;
      align-items: center;
      ${theme.above.lg} {
        position: absolute;
        right: 0;
        top: 40%;
      }
      ${theme.below.md} {
        margin-top: 1rem;
      }
      color: $body-font-color;
      i {
        margin-left: 10px;
      }
    }
    &.secondary {
      background: ${theme.startPage.headerRow.backgroundSecondary};
      * {
        color: ${theme.startPage.headerRow.colorSecondary};
      }
    }
  }

  .border-box {
    padding: 1rem 0.5rem;
    ${theme.above.md} {
      border: 10px solid #fff;
    }
    ${theme.above.lg} {
      padding: 4rem 5px;
    }
    @include xlarge-up {
      padding: 12rem 5px;
    }
  }

  .flex-row {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 2rem;
      padding: 0 0.7rem;
    }
  }

  .project-screen {
    margin: 60px $global-padding !important;
    max-width: 930px;
    box-shadow: 0px 0px 150px #00000054;
    border-radius: 10px;
    overflow: hidden;
    img {
      width: 100%;
    }
    ${theme.above.lg} {
      margin: 100px auto !important;
    }
  }

  .content-text {
    max-width: ${theme.general.maxWidth}-text;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 0 $global-padding;
    ul {
      list-style-type: none;
      margin: 1rem 0 2rem;
      padding: 0;
      li {
        &:before {
          font-family: $font-icon;
          content: $font-icon-space-station;
          margin-right: 10px;
          font-size: 0.8rem;
          display: inline-block;
          color: ${theme.colors.primary};
        }
      }
    }
    .button {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;
