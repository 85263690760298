export const getScorer = (scorers, teamId) => {
  return scorers?.filter((score) => {
    if (score.teamId === teamId && score.goalType !== "OWN_GOAL") {
      return true;
    }
    if (score.teamId !== teamId && score.goalType === "OWN_GOAL") {
      return true;
    }
    return false;
  });
};
