import { css } from "@emotion/core";

const { theme } = require("../Theme");

export const pagePadding = css`
  padding-right: ${theme.general.pagePadding.sm};
  padding-left: ${theme.general.pagePadding.sm};
  ${theme.above.md} {
    padding-right: ${theme.general.pagePadding.md};
    padding-left: ${theme.general.pagePadding.md};
  }
  ${theme.above.lg} {
    padding-right: ${theme.general.pagePadding.lg};
    padding-left: ${theme.general.pagePadding.lg};
  }
`;

//CUSTOM SCROLL BAR
export const scrollBar = `
  overflow: auto;
  padding-right: 5px;
  /* width */
  ::-webkit-scrollbar {
    width: 4px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f9f9f9;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #eee;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #ddd;
  }
`;
