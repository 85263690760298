export const config = {
  wpHost: "https://admin.fcbayernstenungsund.se/",
  gqlUrl: "https://admin.fcbayernstenungsund.se/graphql",
  host: "https://wwww.fcbayernstenungsund.se/",
  logoUrl: {
    desktop:
      "https://admin.fcbayernstenungsund.se/wp-content/uploads/2020/10/logo.png",
    mobile:
      "https://admin.fcbayernstenungsund.se/wp-content/uploads/2020/10/logo.png",
  },
  gtmId: "GTM-KK8LKHJ",
  competitionId: 1,
  year: 2023,
  apiEndpoint: "https://match.uefa.com/v5/",
  routes: {
    blog: {
      name: "Blogg",
      uri: "/blogg/",
      maxPosts: 10,
    },
    project: {
      name: "Projekt",
      uri: "/projekt/",
      maxPosts: 12,
    },
  },
};
