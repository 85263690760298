import React from "react";
import styled from "@emotion/styled";
import SEO from "../SEO/SEO";
import { BlockContent } from "../Blocks/BlockContent";
import InnerContent from "../Layout/Content/InnerContent";
import { theme } from "../Theme";
import { Breadcrumbs } from "../Layout/Breadcrumbs/Breadcrumbs";
import { MaxWidthText } from "../Layout/MaxWidth";

const PageWrapper = styled.article``;

const ContentWrapper = styled(MaxWidthText)`
  padding-bottom: 1rem !important;
`;

export const PageHeader = styled.header`
  padding: 2rem;
  background: ${theme.colors.primary};
  color: ${theme.colors.white};
  text-align: center;
  h1 {
    margin: 0;
  }
`;

const Page = ({ data }) => {
  return (
    <PageWrapper>
      <SEO seo={data.seo} />
      <PageHeader className="page-header">
        {<h1 dangerouslySetInnerHTML={{ __html: data.title }} />}
      </PageHeader>
      <ContentWrapper>
        <Breadcrumbs data={data.seo.breadcrumbs} />
        <InnerContent>
          <BlockContent content={data.content} />
        </InnerContent>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default Page;
