import React from "react";
import { config } from "../../../config";

const Logo = (props) => {
  let src = config.logoUrl.desktop;
  if (props.mobile) src = config.logoUrl.mobile;

  if (props.mobile) {
    return <img src={src} alt="logo" />;
  } else {
    return <img src={src} alt="logo" />;
  }
};

export default Logo;
