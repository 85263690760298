import React, { useState } from "react";
import { Query } from "react-apollo";
import { ShutterLink } from "../../../ui/ShutterLink";
import { DesktopSubMenu } from "./DesktopSubMenu";
import { theme } from "../../../Theme";
import menuQuery from "../MenuQuery.gql";
import styled from "@emotion/styled";
import { menuTreeGen, useActiveMenuItem } from "../helpers";

const DesktoMenuWrapper = styled.nav`
  margin-left: 2rem;
  height: ${theme.desktopHeader.height.lg + "px"};
  &[data-scroll="true"] {
    height: ${theme.desktopHeader.height.scroll + "px"};
  }
  > ul {
    display: flex;
    height: 100%;
    align-items: center;
    > li {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      justify-content: center;
      &:not(:last-child) {
        margin-right: 2rem;
      }
      > button a {
        text-transform: ${theme.desktopHeader.nav.textTransform};
        font-weight: ${theme.desktopHeader.nav.fontWeight};
        font-style: ${theme.desktopHeader.nav.fontStyle};
        font-family: ${theme.desktopHeader.nav.fontFamily};
        font-size: ${theme.desktopHeader.nav.fontSize};
      }
    }
    li.selected {
      > button a {
        color: ${theme.desktopHeader.nav.selectedColor};
      }
    }
    li.active {
      > button a {
        color: ${theme.desktopHeader.nav.activeColor};
      }
    }
  }
`;

const MenuItem = ({ item, scrolling }) => {
  const [subMenu, setShowMenu] = useState(false);

  const url = item.connectedObject ? item.connectedObject.uri : item.url;
  const active = useActiveMenuItem(url);
  return (
    <li
      onMouseEnter={() => {
        if (item.children.length > 0) setShowMenu(true);
      }}
      onMouseLeave={() => {
        setShowMenu(false);
      }}
      className={active ? active : ""}
    >
      <button>
        <ShutterLink
          onClick={() => {
            setShowMenu(false);
          }}
          to={url}
        >
          {item.label}
        </ShutterLink>
      </button>
      {subMenu && (
        <DesktopSubMenu
          item={item}
          setShowMenu={setShowMenu}
          scrolling={scrolling}
        />
      )}
    </li>
  );
};

export const DesktopMenu = ({ scrolling }) => {
  return (
    <DesktoMenuWrapper data-scroll={scrolling}>
      <Query query={menuQuery}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error || !data) return null;
          const menu = data.menus.nodes[0];
          const menuTree = menuTreeGen(menu.menuItems.nodes);
          return (
            <ul>
              {menuTree.map((item) => {
                return (
                  <MenuItem item={item} key={item.id} scrolling={scrolling} />
                );
              })}
            </ul>
          );
        }}
      </Query>
    </DesktoMenuWrapper>
  );
};
