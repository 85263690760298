export const decodeHtml = (text) => {
  if (typeof document !== "undefined") {
    var txt = document.createElement("textarea");
    txt.innerHTML = text;
    return txt.value;
  }
  return text;
};

export const dateFormat = (date) => {
  const weekday = [
    "Söndag",
    "Måndag",
    "Tisdag",
    "Onsdag",
    "Torsdag",
    "Fredag",
    "Lördag",
  ];

  const time = new Date(date);
  return {
    date: `${time.getFullYear()}-${("0" + (time.getMonth() + 1)).slice(-2)}-${(
      "0" + time.getDate()
    ).slice(-2)}`,
    time: `${("0" + time.getHours()).slice(-2)}:${(
      "0" + time.getMinutes()
    ).slice(-2)}`,
    day: weekday[time.getDay()],
  };
};

export function addTime(date, minutes) {
  const addTime = minutes * 60000;
  const newTime = date.getTime() + addTime;
  return new Date(newTime);
}

export function invertColor(hex) {
  if (!hex) return "#FFF";

  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error("Invalid HEX color.");
  }
  // invert color components
  var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
    g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
    b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
  // pad each with zeros and return
  return "#" + padZero(r) + padZero(g) + padZero(b);
}

export function padZero(str, len) {
  len = len || 2;
  var zeros = new Array(len).join("0");
  return (zeros + str).slice(-len);
}
