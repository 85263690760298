import { css } from "@emotion/core";
import { theme, getFontSize } from "../Theme";

export const typography = css`
  //HEADERS
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${theme.typography.header.fontFamily};
    font-weight: ${theme.typography.header.fontWeight};
    font-style: ${theme.typography.header.fontStyle};
    text-transform: ${theme.typography.header.textTransform};
    letter-spacing: ${theme.typography.header.letterSpacing};
    line-height: 1.5;
    margin: 1rem 0 0.5rem;
    &.body-font {
      font-family: ${theme.typography.fontFamily};
      font-weight: ${theme.typography.fontWeight};
    }
    &.secondary {
      color: ${theme.typography.header.secondColor.color} !important;
    }
    &.second-color {
      color: ${theme.typography.header.secondColor.color} !important;
    }
    &.second-font {
      font-family: ${theme.typography.header.secondFont.fontFamily} !important;
      font-weight: ${theme.typography.header.secondFont.fontWeight} !important;
    }
    &.thin {
      font-weight: ${theme.fontWeights.light} !important;
    }
    &.ultra {
      font-weight: ${theme.fontWeights.ultra} !important;
    }
  }
  .secondary-font {
    font-family: ${theme.fonts.secondary};
    font-weight: ${theme.typography.header.fontWeight};
    letter-spacing: ${theme.typography.header.letterSpacing};
  }

  h1 {
    ${getFontSize(theme.typography.header.fontSize[1])}
  }
  h2 {
    ${getFontSize(theme.typography.header.fontSize[2])}
  }
  h3 {
    ${getFontSize(theme.typography.header.fontSize[3])}
  }
  h4 {
    ${getFontSize(theme.typography.header.fontSize[4])}
  }
  h5 {
    ${getFontSize(theme.typography.header.fontSize[5])}
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    &.normal {
      font-weight: ${theme.fontWeights.normal} !important;
    }
    &.bold {
      font-weight: ${theme.fontWeights.bold} !important;
    }
    &.thin {
      font-weight: ${theme.fontWeights.light} !important;
    }
    &.ultra {
      font-weight: ${theme.fontWeights.ultra} !important;
    }
  }

  a,
  a:active {
    color: ${theme.colors.font};
    text-decoration: none;
  }
  a:hover {
  }

  .seo-box a {
    color: ${theme.colors.link};
  }

  p {
    font-size: 1rem;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    &.large {
      ${theme.above.md} {
        font-size: 1.4rem;
      }
    }
  }

  strong {
    font-weight: ${theme.fontWeights.bold};
  }

  .content-area {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 1em 0 1.666em 0;
    }
    p {
      margin: 0 0 1em 0;
    }
    > *:first-of-type {
      margin-top: 0 !important;
    }
    &.small {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 0.5em 0 1em 0;
      }
    }
  }
`;
