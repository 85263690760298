import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useFetch } from "../Util/Hooks/useFetch";
import { MatchList } from "../MatchList/MatchList";
import { UpcomingMatches } from "./UpcomingMatches";
import { config } from "../../config";

const Wrapper = styled.div`
  position: relative;
  > header {
    margin-top: 2rem;
  }
`;

const MATCHES_ID_URL = (ids) =>
  `${config.apiEndpoint}matches?matchId=${ids}&offset=0&limit=8`;

const LIVESCORE_URL = `${config.apiEndpoint}livescore?competitionId=${config.competitionId}&optionalFields=PLAYER_EVENTS`;

export const Livescore = () => {
  const [matchData, setMatchData] = useState(false);
  const [update, setUpdate] = useState(false);
  const { data, status } = useFetch(LIVESCORE_URL);

  const ids = data.length
    ? data.map((item) => {
        return item.id;
      })
    : false;

  //MERGE DATA
  useEffect(() => {
    function updateMatches(ids) {
      fetch(MATCHES_ID_URL(ids))
        .then((res) => res.json())
        .then((data) => setMatchData(data.reverse()));
    }

    if (!update && ids.length) {
      updateMatches(ids);
      setUpdate(
        setInterval(() => {
          updateMatches(ids);
        }, 30000)
      );
    }
  }, [data, ids, update]);

  useEffect(() => {
    return () => {
      clearInterval(update);
    };
  }, [update]);

  if (matchData) {
    return (
      <Wrapper>
        <header>
          <h3>Aktuella matcher</h3>
        </header>
        <MatchList matchData={matchData} />
      </Wrapper>
    );
  } else if (status === "fetched" && data.length === 0) {
    return <UpcomingMatches />;
  } else {
    return null;
  }
};
