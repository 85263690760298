import { css } from "@emotion/core";

export const image = css`
  .wp-block-image {
    text-align: center;
    margin: 0;
    img{
      display: block;
    }
    &.full {
      img {
        width: 100%;
      }
    }
  }
`;
