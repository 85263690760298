import { Link } from "react-router-dom";
import { Query } from "react-apollo";
import React, { useState, useEffect } from "react";
import { config } from "../../config";
import { useInView } from "react-intersection-observer";
import Media from "react-media";
import { mediaQueries } from "../Theme";

//QUERIES TO PREFETCH
import routeQuery from "../RouteQuery.gql";
import catQuery from "../Blog/Categories/CategoriesQuery.gql";
import postsQuery from "../Blog/PostsQuery.gql";
import startPageQuery from "../StartPage/StartPageQuery.gql";
import { ImagePopup } from "./ImagePopup";

const QueryComponent = ({ slug }) => {
  const slugFix = slug.substr(0, 1) !== "/" ? "/" + slug : slug;
  const slugSplit = slugFix.split("/");
  const isBlog =
    slugFix === config.routes.blog.uri ||
    (slugFix.search(config.routes.blog.uri) !== -1 && slugSplit.length === 3);
  const isStart = slugFix === "/";

  //STANDARD PAGE QUERY
  const query = isStart ? startPageQuery : routeQuery;
  const variables = {
    uri: isBlog ? config.routes.blog.uri : slugFix,
  };

  //IF ITS A LIST PAGE
  const isList = isBlog;

  //LIST VARIABLES
  const listVariables = isBlog
    ? {
        category:
          slugSplit.length === 3 && slugSplit[2].length > 0
            ? slugSplit[2]
            : null,
        first: config.routes.blog.maxPosts,
        after: null,
      }
    : false;

  //LIST QUERY
  let listQuery = postsQuery;

  return (
    <>
      <Query ssr={false} query={query} variables={variables}>
        {() => null}
      </Query>
      {isList && (
        <>
          <Query ssr={false} query={listQuery} variables={listVariables}>
            {() => null}
          </Query>
          {isBlog && (
            <Query ssr={false} query={catQuery}>
              {() => null}
            </Query>
          )}
        </>
      )}
    </>
  );
};

//LINK FOR MOBILE & FETCH WHEN LINK IS IN VIEWPORT
const MobileLink = ({ children, slug, ...linkProps }) => {
  const [ref, inView] = useInView({
    threshold: 0,
  });
  const [fetchUri, setfetchUri] = useState(false);

  useEffect(() => {
    if (inView) setfetchUri(true);
  }, [inView, setfetchUri]);

  return (
    <span ref={ref}>
      <Link to={slug} {...linkProps}>
        {children}
      </Link>
      {fetchUri && <QueryComponent slug={slug} />}
    </span>
  );
};

//LINK FOR DESKTOP & FETCH DATA WHEN LINK IS HOVERED
const DesktopLink = ({ children, slug, ...linkProps }) => {
  const [fetchUri, setfetchUri] = useState(false);
  return (
    <span
      onMouseEnter={() => {
        setfetchUri(true);
      }}
    >
      <Link to={slug} {...linkProps}>
        {children}
      </Link>
      {fetchUri && <QueryComponent slug={slug} />}
    </span>
  );
};

export const ShutterLink = ({ to, children, ...linkProps }) => {
  const slug =
    to.search(config.wpHost) !== -1 && to.search("wp-content") === -1
      ? to.replace(config.wpHost, "/")
      : to;

  //IMAGE POPUP
  if (slug.match(/.(jpg|jpeg|png|gif)$/i))
    return <ImagePopup children={children} slug={slug} {...linkProps} />;

  //EXTERNAL LINK
  if (
    slug.search("http") !== -1 ||
    slug.search("mailto:") !== -1 ||
    slug.search("tel:") !== -1
  )
    return (
      <a href={slug} {...linkProps}>
        {children}
      </a>
    );
  return (
    <Media query={mediaQueries.above.lg}>
      {(matches) => {
        return matches ? (
          <DesktopLink children={children} slug={slug} {...linkProps} />
        ) : (
          <MobileLink children={children} slug={slug} {...linkProps} />
        );
      }}
    </Media>
  );
};
