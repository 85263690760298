import App from "./components/App";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import { hydrate } from "react-dom";
import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import fetch from "node-fetch";
import introspectionQueryResultData from "./scheme/fragmentTypes.json";
import { config } from "./config";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const client = new ApolloClient({
  cache: new InMemoryCache({ fragmentMatcher }).restore(
    window.__APOLLO_STATE__
  ),
  link: createHttpLink({
    uri: config.gqlUrl,
    fetch: fetch,
  }),
});

hydrate(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
);

if (module.hot) {
  module.hot.accept();
}
