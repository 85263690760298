import React from "react";
import styled from "@emotion/styled";
import MaxWidth from "./Layout/MaxWidth";
import SEO from "./SEO/SEO";
import { BlockContent } from "./Blocks/BlockContent";
import InnerContent from "./Layout/Content/InnerContent";
import SiteContent from "./Util/SiteContent/SiteContent";

const PageWrapper = styled.article`
  padding-bottom: 3rem;
`;

const PageHeader = styled.header`
  padding: 2rem;
  text-align: center;
  h1 {
    margin: 0;
  }
`;

const NotFoundPage = () => {
  return (
    <SiteContent id="not-found">
      {(layout) => (
        <PageWrapper>
          <SEO seo={layout} />
          <PageHeader>
            <MaxWidth>
              {<h1 dangerouslySetInnerHTML={{ __html: layout.title }} />}
            </MaxWidth>
          </PageHeader>
          <MaxWidth>
            <InnerContent>
              <BlockContent content={layout.content} />
            </InnerContent>
          </MaxWidth>
        </PageWrapper>
      )}
    </SiteContent>
  );
};

export default NotFoundPage;
