import React from "react";
import SiteContent from "../../../Util/SiteContent/SiteContent";
import styled from "@emotion/styled";
import { PagePadding, Inner, PagePaddingWrapper } from "../../MaxWidth";
import { theme } from "../../../Theme";

const TopBarSpacer = styled.div`
  height: ${theme.topBar.height.sm + "px"};
  ${theme.above.lg} {
    height: ${theme.topBar.height.lg + "px"};
  }
`;

const TopBarWrapper = styled.div`
  height: ${theme.topBar.height.sm + "px"};
  background: ${theme.topBar.background};
  position: fixed;
  width: 100%;
  z-index: 99;
  ${theme.above.lg} {
    height: ${theme.topBar.height.lg + "px"};
  }
  ${PagePaddingWrapper}, ${Inner} {
    height: 100%;
  }
`;

const TopBarContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 0.8rem;
    font-weight: ${theme.fontWeights.normal};
    margin: 0;
    color: ${theme.topBar.color};
    ${theme.below.md} {
      letter-spacing: -0.1px;
    }
    ${theme.above.md} {
      font-size: 1rem;
    }
  }
`;

const TopBar = () => {
  return (
    <>
      <TopBarSpacer />
      <TopBarWrapper>
        <PagePadding>
          <Inner>
            <SiteContent id="top-bar">
              {(layout) => (
                <TopBarContent
                  dangerouslySetInnerHTML={{
                    __html: layout.content,
                  }}
                />
              )}
            </SiteContent>
          </Inner>
        </PagePadding>
      </TopBarWrapper>
    </>
  );
};

export default TopBar;
