import React from "react";
import styled from "@emotion/styled";
import { mediaQueries } from "../Theme";

const StyledImage = styled.img`
  max-width: 100%;
  display: block;
  &[data-crop="true"] {
    ${(props) => `
      width: 9999px;
      height: ${props.heights[0] + "px"};
      object-fit: cover;
    `}
`;

const renderSizes = (sizes) => {
  let standardValue = "100vw";
  let imgSizes = [];
  for (let i = 0; i < 5; i++) {
    if (sizes[i]) {
      let unit = "";
      if (!isNaN(sizes[i])) unit = "vw";
      standardValue = sizes[i] + unit;
      imgSizes.push(sizes[i] + unit);
    } else imgSizes.push(standardValue);
  }
  return `${mediaQueries.above.sm} ${imgSizes[1]}, ${mediaQueries.above.md} ${imgSizes[2]}, ${mediaQueries.above.lg} ${imgSizes[3]}, ${mediaQueries.above.xl} ${imgSizes[4]}, ${imgSizes[0]}`;
};

const Image = ({
  image,
  className,
  sizes = [],
  heights = [],
  crop = false,
}) => {
  const renderedSizes = renderSizes(sizes);
  return (
    <StyledImage
      data-crop={crop}
      className={className}
      src={image.sourceUrl}
      srcSet={image.srcSet}
      sizes={renderedSizes}
      alt={image.altText}
      heights={heights}
    />
  );
};

export default Image;
