import { css } from "@emotion/core";
import { theme } from "../../Theme";

export const mediaText = css`
  .wp-block-media-text {
    .wp-block-media-text__content {
      height: 100%;
      padding: ${theme.blocks.mediaTextPadding.sm};
      ${theme.above.lg} {
        padding: ${theme.blocks.mediaTextPadding.lg};
      }
      p {
        //max-width: 400px;
      }
      ul {
        margin-bottom: 1rem;
        /*margin: 0;
      padding: 0;
      list-style-type: none;*/
      }
      > *:first-child,
      > div > *:first-child {
        margin-top: 0;
      }
    }
    &.has-media-on-the-right {
      .wp-block-media-text__content {

      }
    }
    .wp-block-media-text__media {
    }
  }

  .full-screen .wp-block-media-text,
  .wp-block-media-text.background,
  .wp-block-media-text.background-light {
    .wp-block-media-text__content {
      padding: ${theme.blocks.mediaTextPadding.sm};
      ${theme.above.lg} {
        padding: ${theme.blocks.mediaTextPadding.lg};
      }
    }
  }

  //CUSTOM CLASS
  .image-height.wp-block-media-text.is-image-fill {
    .wp-block-media-text__media {
      ${theme.above.md} {
        min-height: 600px;
      }
    }
  }

  .flex-text {
    .wp-block-media-text__content {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    &.space {
      .wp-block-media-text__content {
        justify-content: space-evenly;
      }
    }
  }

  .no-padding {
    .wp-block-media-text__content {
      padding: 0 !important;
    }
  }

  .image-padding {
    .wp-block-media-text__media {
      height: 100%;
      padding: ${theme.blocks.image.padding.sm};
      padding-bottom: 0;
      ${theme.above.md} {
        padding: ${theme.blocks.image.padding.md};
      }
      ${theme.above.lg} {
        padding: ${theme.blocks.image.padding.lg};
      }
    }
  }

  .wp-block-media-text.stack-on-medium {
    ${theme.below.md} {
      grid-template-columns: 100% !important;
      .wp-block-media-text__media,
      .wp-block-media-text__content {
        grid-column: 1;
      }
      .wp-block-media-text__media {
        grid-row: 1;
      }
      .wp-block-media-text__content {
        grid-row: 2;
      }
    }
  }

  //OVERIDE STANDARD ALWAYS SHOW IMAGE ON TOP
  @media (max-width: 600px) {
    .wp-block-media-text.is-stacked-on-mobile.image-first,
    .wp-block-media-text.is-stacked-on-medium.image-first {
      .wp-block-media-text__media {
        grid-row: 1 !important;
      }
      .wp-block-media-text__content {
        grid-row: 2 !important;
      }
    }
  }

  //CROP
  /*.wp-block-media-text:not(.image-padding):not(.no-crop) {
  .wp-block-media-text__media {
    &.crop {
      ${theme.above.md} {
        position: relative;
        overflow: hidden;
        height: 100%;
        img,
        video {
          position: absolute;
          left: 50%;
          top: 50%;
          height: 100%;
          width: auto !important;
          max-width: 9999px;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
*/

  @media (min-width: 600px) {
    .image-33 {
      grid-template-columns: 33% auto !important;
      &.has-media-on-the-right {
        grid-template-columns: auto 33% !important;
      }
    }

    .image-40 {
      grid-template-columns: 40% auto !important;
      &.has-media-on-the-right {
        grid-template-columns: auto 40% !important;
      }
    }

    .image-50 {
      grid-template-columns: 50% auto !important;
      &.has-media-on-the-right {
        grid-template-columns: auto 50% !important;
      }
    }

    .image-66 {
      grid-template-columns: 66% auto !important;
      &.has-media-on-the-right {
        grid-template-columns: auto 66% !important;
      }
    }

    .image-75 {
      grid-template-columns: 75% auto !important;
      &.has-media-on-the-right {
        grid-template-columns: auto 75% !important;
      }
    }
  }

  .image-33,
  .image-40,
  .image-66,
  .image-75 {
    ${theme.below.md} {
      grid-template-columns: 50% auto;
      &.has-media-on-the-right {
        grid-template-columns: auto 50%;
      }
    }
  }

  //IMAGE BACKGROUND
  .image-background {
    .wp-block-media-text__media {
      background: ${theme.blocks.image.background};
      padding: ${theme.blocks.image.backgroundPadding.sm};
      height: 100%;
      display: flex;
      align-items: center;
      ${theme.above.md} {
        padding: ${theme.blocks.image.backgroundPadding.md};
      }
      * {
        color: white !important;
      }
    }
    &:nth-of-type(2n) .wp-block-media-text__media {
      background: ${theme.blocks.background};
    }
    &:nth-of-type(3n) .wp-block-media-text__media {
      background: ${theme.blocks.background};
    }
    &:nth-of-type(4n) .wp-block-media-text__media {
      background: ${theme.blocks.background};
    }
  }
`;
