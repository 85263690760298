import React from "react";
import styled from "@emotion/styled";

const StyledButton = styled.button`
  line-height: 50px !important;
  height: 50px;
  display: inline-block;
  color: #fff !important;
  background: #b26ffc;
  text-transform: uppercase;
  padding: 0 30px;
  text-decoration: none !important;
  font-size: 1rem;
  border: none;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  border-radius: 0;
  position: relative;
  letter-spacing: 1px;
  &.overlay {
    line-height: 50px !important;
    height: 50px;
    display: inline-block;
    color: #fff !important;
    background: #b26ffc;
    text-transform: uppercase;
    padding: 0 30px;
    text-decoration: none !important;
    font-size: 1rem;
    border: none;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    border-radius: 0;
    position: relative;
    letter-spacing: 1px;
    background: rgba(255, 255, 255, 0.2) !important;
    color: #fff !important;
    border: none;
    height: 40px;
    line-height: 40px !important;
    padding: 0 20px;
  }
`;

export const Button = ({ children, className, ...props }) => {
  return (
    <StyledButton className={className} {...props}>
      {children}
    </StyledButton>
  );
};
