import React, { useEffect, useState } from "react";
import Media from "react-media-universal";
import { mediaQueries } from "../../Theme";
import { DesktopHeader } from "./Desktop/DesktopHeader";
import { MobileHeader } from "./Mobile/MobileHeader";
import TopBar from "./TopBar/TopBar";

const Header = () => {
  const [scrolling, setScrolling] = useState(false);

  const scrollListener = (e) => {
    if (window.scrollY <= 50 && scrolling) {
      setScrolling(false);
    } else if (window.scrollY > 50 && !scrolling) {
      setScrolling(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollListener);
    return () => {
      window.removeEventListener("scroll", scrollListener);
    };
  });
  return (
    <>
      <TopBar />
      <Media query={mediaQueries.above.lg}>
        {(matches) => {
          return matches ? (
            <DesktopHeader scrolling={scrolling} />
          ) : (
            <MobileHeader scrolling={scrolling} />
          );
        }}
      </Media>
    </>
  );
};

export default Header;
