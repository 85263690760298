import React from "react";
import styled from "@emotion/styled";
import { theme } from "../../Theme";
import { Modal } from "../../ui/Modal";

const StyledModal = styled(Modal)`
  padding: 0;
  @media screen and (min-width: 800px) {
    padding: 2%;
  }
  .modal-content {
    max-width: 400px;
    width: 100%;
    position: static;
    .close-modal {
      top: 10px;
      right: 30px;
    }
    .player-image {
      img {
        width: 100%;
      }
    }
    .player-info {
      padding: 2rem;
      h3 {
        color: ${theme.colors.font};
        margin-bottom: 1rem;
      }
    }
  }
`;

const PopupWrapper = styled.div`
  background: white;
  color: ${theme.colors.font};
`;

export const PlayerPopup = ({ player, close }) => {
  const person = player.player;
  return (
    <StyledModal close={close} lockBackground={true}>
      <PopupWrapper>
        <div className="player-image">
          <img src={person.imageUrl} alt={person.internationalName} />
        </div>
        <div className="player-info">
          <h3>
            {person.internationalName} #{player.jerseyNumber}
          </h3>
          <p>Position: {person.fieldPosition}</p>
          <p>Land: {person.translations.countryName.EN}</p>
          <p>Ålder: {person.age}</p>
          <p>Födsel: {person.birthDate}</p>
        </div>
      </PopupWrapper>
    </StyledModal>
  );
};
