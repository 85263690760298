import { css } from "@emotion/core";
import { theme } from "../Theme";

export const forms = css`
  input,
  text-area,
  select {
    font-family: ${theme.typography.fontFamily};
    font-size: ${theme.typography.fontSize};
  }
  select {
    font-size: 1rem;
  }
`;
