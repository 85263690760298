import React from "react";
import styled from "@emotion/styled";
import { dateFormat } from "../Util/Helpers";
import { theme } from "../Theme";
import { ShutterLink } from "../ui/ShutterLink";
import { config } from "../../config";

const MetaWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
  padding: 5px 0;
  border-top: 1px solid ${theme.colors.borderLight};
  border-bottom: 1px solid ${theme.colors.borderLight};
  img {
    width: 30px;
    margin-right: 5px;
  }
  .meta-text {
    font-size: 0.8rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    > span:not(.categories),
    .category {
      margin-top: 2px;
      margin-bottom: 2px;
      background: #f5f5f5;
      padding: 0 5px;
      line-height: 31px;
      display: inline-block;
      &:not(:last-of-type) {
        margin-right: 5px;
      }
    }
    .name {
    }
    .date-wrapper {
      font-style: italic;
    }
    .time {
      margin-left: 3px;
    }
    .categories {
      .category {
        font-weight: ${theme.fontWeights.bold};
        text-transform: uppercase;
        font-size: 0.7rem;
      }
    }
  }
`;

export const PostMeta = ({ post, className = "", showCat = false }) => {
  const date = dateFormat(post.date);
  const author = post.author.node;
  const categories = showCat ? post.categories && post.categories.nodes : false;
  return (
    <MetaWrapper className={`${className} post-meta-wrapper`}>
      {author.avatar && <img src={author.avatar.url} alt={author.name} />}
      <div className="meta-text">
        <span className="name">{author.name}</span>
        <span className="date-wrapper">
          <span className="date">{date.date}</span>
          <span className="time">{date.time}</span>
        </span>
        {categories && (
          <span className="categories">
            {categories.map((cat, index) => (
              <span className="category" key={index}>
                <ShutterLink to={config.routes.blog.uri + cat.slug}>
                  {cat.name}
                </ShutterLink>
              </span>
            ))}
          </span>
        )}
      </div>
    </MetaWrapper>
  );
};
