import React from "react";
import styled from "@emotion/styled";
import { theme } from "../Theme";
import { Modal } from "../ui/Modal";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import {
  faLandmark,
  faFutbol,
  faGlobeEurope,
  faHome,
} from "@fortawesome/pro-light-svg-icons";

const StyledModal = styled(Modal)`
  .modal-content {
    background: white;
    max-width: 1020px;
  }
`;

const PopupWrapper = styled.div`
  background: white;
  padding: 2rem;
  color: ${theme.colors.font};
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: ${theme.colors.font};
  }
  p {
    max-width: none;
  }
  header {
    margin-bottom: 2rem;
    .logos {
      img {
        max-width: 100px;
        ${theme.above.md} {
          max-width: 160px;
        }
      }
    }
    svg {
      margin-right: 5px;
    }
    .meta {
      p {
        margin: 0 0 5px 0;
      }
    }
  }
  .popup-content {
    text-align: left;
  }
`;

export const TeamPopup = ({ team, setPopup }) => {
  return (
    <StyledModal
      close={() => {
        setPopup(false);
      }}
    >
      <PopupWrapper>
        <header>
          <div className="logos">
            {team.strTeamJersey && (
              <img src={team.strTeamJersey} alt={team.strTeam} />
            )}
            {team.strTeamBadge && (
              <img src={team.strTeamBadge} alt={team.strTeam} />
            )}
          </div>
          <h3>{team.strTeam}</h3>
          <div className="meta">
            <p>
              <Icon icon={faHome} />
              {team.strStadium}
            </p>
            <p>
              <Icon icon={faFutbol} />
              {team.strLeague}
            </p>
            <p>
              <Icon icon={faGlobeEurope} />
              {team.strCountry}
            </p>
            <p>
              <Icon icon={faLandmark} />
              {team.intFormedYear}
            </p>
          </div>
        </header>
        <div className="popup-content">
          <p>{team.strDescriptionEN}</p>
        </div>
        <div className="banners">
          {team.strTeamBanner && (
            <img src={team.strTeamBanner} alt={team.strTeam} />
          )}
          {team.strTeamFanart1 && (
            <img src={team.strTeamFanart1} alt={team.strTeam} />
          )}
          {team.strTeamFanart2 && (
            <img src={team.strTeamFanart2} alt={team.strTeam} />
          )}
          {team.strTeamFanart3 && (
            <img src={team.strTeamFanart3} alt={team.strTeam} />
          )}
          {team.strTeamFanart4 && (
            <img src={team.strTeamFanart4} alt={team.strTeam} />
          )}
        </div>
      </PopupWrapper>
    </StyledModal>
  );
};
