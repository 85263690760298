import React from "react";
import styled from "@emotion/styled";
import Image from "../ui/Image";
import { ShutterLink } from "../ui/ShutterLink";
import { PostMeta } from "../Post/PostMeta";

//STYLED COMPONENTS
const ItemWrapper = styled.article`
  display: flex;
  margin: 4rem 0;
  &:first-of-type {
    margin-top: 2rem;
  }
  flex-direction: column;
  .post-meta-wrapper {
    margin: 0.4rem 0 0.8rem;
  }
`;
const ItemImage = styled.div`
  width: 200px;
  max-width: 50%;
  margin-right: 1rem;
  a,
  img {
    display: block;
  }
`;
const Excerpt = styled.div`
  p {
    margin-bottom: 0.5rem;
  }
`;
const Title = styled.h2`
  margin: 0 0 0 0;
`;
const TextWrapper = styled.div`
  display: flex;
  width: 100%;
`;
const ContentWrapper = styled.div`
  display: flex;
`;

//BLOG ITEMS COMPONENT
export const ListItem = ({ node }) => {
  const postUrl = node.uri;
  return (
    <ItemWrapper>
      <ShutterLink to={postUrl}>
        <Title>{node.title}</Title>
      </ShutterLink>
      <PostMeta post={node} />
      <ContentWrapper>
        {node.featuredImage && node.featuredImage.node && (
          <ItemImage>
            <Image
              image={node.featuredImage.node}
              sizes={["200px"]}
              crop={true}
              heights={[100]}
            />
          </ItemImage>
        )}
        <TextWrapper>
          <Excerpt
            dangerouslySetInnerHTML={{
              __html: node.excerpt,
            }}
          />
        </TextWrapper>
      </ContentWrapper>
    </ItemWrapper>
  );
};
