import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { theme } from "../Theme";
import { Group } from "./Group";
import useFetch from "use-http";
import { config } from "../../config";

const StandingsWrapper = styled.div`
  min-height: 3000px;
  h2 {
    color: #00004b;
    margin-bottom: 0;
    margin-top: 3rem;
  }
  .score {
    text-align: center;
  }
  table {
    width: 100%;
    max-width: 100%;
    border-spacing: 0;
  }
  .table-standings {
    td {
      border-bottom: 1px solid lightgrey;
      width: auto;
      padding: 10px;
    }
    th {
      font-weight: 500;
      text-align: center;
      color: #768398;
      font-size: 13px;
      border-bottom: 1px solid lightgrey;
      width: 60px;
      padding: 1rem;
      ${theme.below.sm} {
        width: 30px;
        padding: 0;
      }
    }
    th:first-child {
      width: auto;
    }
    ${theme.above.md} {
      .label-small {
        display: none;
      }
    }
    ${theme.below.md} {
      .label-big {
        display: none;
      }
      .label-small {
        display: block;
      }
    }
    td:last-child {
      font-weight: bold;
      font-size: 17px;
    }
  }
  .teamName {
    display: flex;
    align-items: center;
    p {
      margin-bottom: 0;
      padding-left: 8px;
      ${theme.below.md} {
        font-size: 14px;
      }
    }
    img {
      height: 40px;
      ${theme.below.md} {
        min-width: 32px;
        width: 32px;
        height: 32px;
      }
    }
  }
  .group-details {
    color: ${theme.colors.secondary};
    margin-top: 1rem;
    margin-bottom: 1rem;
    span {
      padding: 6px;
    }
    :hover {
      cursor: pointer;
    }
  }
`;

const STANDINGS_URL = `https://standings.uefa.com/v1/standings?competitionId=${config.competitionId}&seasonYear=${config.year}&phase=TOURNAMENT`;

export const ChampionsLeagueStandings = () => {
  const { data } = useFetch(STANDINGS_URL, {}, []);
  if (data && data.length > 0) {
    return (
      <StandingsWrapper>
        {data.map((group, index) => (
          <Group key={index} group={group} />
        ))}
      </StandingsWrapper>
    );
  } else return false;
};
