//import React from "react";
import styled from "@emotion/styled";

export const LoadingBox = styled.div`
  width: 100%;
  height: 6rem;
  background: #eee;
`;

export const LoadingBar = styled.div`
  width: 100%;
  height: 2rem;
  background: #eee;
`;
