import React from "react";
import styled from "@emotion/styled";
import "./blockStandardStyles.css";
import { BlockStyles } from "./BlockStyles";
import parse, { domToReact } from "html-react-parser";
import { ShutterLink } from "../ui/ShutterLink";
import BlogWidget from "../Blog/BlogWidget";
import { MatchFeed } from "../Feed/MatchFeedSportsDB";
import { Livescore } from "../Livescore/Livescore";
import { ChampionsLeagueStandings } from "../Standings/Standings";
import { LatestResult } from "../Livescore/LatestResult";

const Content = styled.div``;

export const BlockContent = ({ content, className }) => {
  if (!content) return null;
  const options = {
    replace: (domNode) => {
      if (domNode && !domNode.attribs) return;
      if (domNode.attribs.class === "blog-row") {
        return <BlogWidget />;
      }
      if (domNode.attribs.class === "feed-row") {
        return <MatchFeed />;
      }
      if (domNode.attribs.class === "livescore-wrapper") {
        return <Livescore />;
      }
      if (domNode.attribs.class === "latest-result-wrapper") {
        return <LatestResult />;
      }
      if (domNode.attribs.class === "standings-wrapper") {
        return <ChampionsLeagueStandings />;
      }
      if (domNode.name === "a") {
        return (
          <ShutterLink
            to={domNode.attribs.href}
            className={domNode.attribs.class}
          >
            {domToReact(domNode.children, options)}
          </ShutterLink>
        );
      }
    },
  };

  return (
    <BlockStyles>
      <Content className={`block-wrapper ${className}`}>
        {parse(content, options)}
      </Content>
    </BlockStyles>
  );
};
