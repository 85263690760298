import React from "react";
import { Query } from "react-apollo";
import styled from "@emotion/styled";
import { config } from "../../../config";
import { ShutterLink } from "../../ui/ShutterLink";
import catQuery from "./CategoriesQuery.gql";

//STYLED COMPONENTS
const CategoryListWrapper = styled("section")`
  padding: 1rem 0px;
  width: 100%;
  position: relative;
`;
const CatList = styled("ul")`
  width: 100%;
  justify-content: center;
  white-space: nowrap;
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
`;
const CatItem = styled("li")`
  text-align: center;
  border-bottom: 1px solid white;
  margin: 1rem;
  &.selected {
    border-color: #333;
  }
  h4 {
    margin: 0;
  }
`;

const LoadingBar = styled("div")`
  width: 100%;
  max-width: 600px;
  height: 20px;
  background: #eee;
  margin: 1rem auto;
`;

//CATEGORY LIST COMPONENT
export const Categories = ({ selectedCategory, activeurl }) => {
  const blogUri = config.routes.blog.uri;
  return (
    <Query query={catQuery}>
      {({ loading, error, data }) => {
        if (error) return null;
        return (
          <CategoryListWrapper>
            {loading ? (
              <LoadingBar />
            ) : (
              <CatList>
                <CatItem className={!selectedCategory ? "selected" : ""}>
                  <h4>
                    <ShutterLink to={blogUri}>Se alla</ShutterLink>
                  </h4>
                </CatItem>
                {data.categories.nodes.map((category, index) => {
                  return (
                    <CatItem
                      key={index}
                      className={
                        category.slug === selectedCategory ? "selected" : ""
                      }
                    >
                      <h4>
                        <ShutterLink to={blogUri + category.slug}>
                          {category.name}
                        </ShutterLink>
                      </h4>
                    </CatItem>
                  );
                })}
              </CatList>
            )}
          </CategoryListWrapper>
        );
      }}
    </Query>
  );
};
