import { css } from "@emotion/core";
import { theme } from "../../Theme";

export const table = css`
  .wp-block-table {
    margin: 1rem 0;
    table {
      border: 1px solid ${theme.colors.border};
      padding: 0px;
      border-spacing: 1px;
      tr {
        td {
          padding: 3px 4px;
        }
        &:nth-of-type(-n + 7) {
          td {
            font-weight: bold;
          }
        }
        &:nth-of-type(7) {
          td {
            border-bottom: 1px solid #666;
          }
        }
      }
      tr:nth-of-type(1) {
        background: ${theme.colors.secondary};
        color: white;
      }
      tr:nth-of-type(2n) {
        background: #eee;
      }
    }
  }
`;
