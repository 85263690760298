import { css } from "@emotion/core";
import { theme } from "../Theme";

export const icons = css`
  //STANDARD FONTAWESOME ICON SIZE

  svg:not(:root).svg-inline--fa {
    overflow: visible;
  }

  .svg-inline--fa {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
  }

  .svg-inline--fa {
    width: 1.25em;
    font-size: 1rem;
  }

  .icon-2x {
    font-size: 1.5rem;
    ${theme.above.lg} {
      font-size: 2rem;
    }
  }
  .icon-3x {
    font-size: 2rem;
    ${theme.above.lg} {
      font-size: 3rem;
    }
  }
  .icon-4x {
    font-size: 2rem;
    ${theme.above.lg} {
      font-size: 4rem;
    }
  }
  .icon-5x {
    font-size: 3rem;
    ${theme.above.lg} {
      font-size: 5rem;
    }
  }
  .icon-6x {
    font-size: 3rem;
    ${theme.above.lg} {
      font-size: 6rem;
    }
  }
`;
