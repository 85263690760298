import React from "react";
import { Helmet } from "react-helmet";
import { Html5Entities } from "html-entities";
import { config } from "../../config";

function convertUrl(url) {
  return url && url.replace(config.wpHost, config.host);
}

export const SEO = ({ seo, author = { name: "" } }, lang = "sv_SE") => {
  if (!seo) return null;
  const htmlEntities = new Html5Entities();
  return (
    <>
      <Helmet>
        {seo.title && <title>{htmlEntities.decode(seo.title)}</title>}
        {seo.metaDesc && (
          <meta
            name="description"
            content={htmlEntities.decode(seo.metaDesc)}
          />
        )}
        {seo.focuskw && (
          <meta name="keywords" content={htmlEntities.decode(seo.focuskw)} />
        )}
        {author.name && <meta name="author" content={author.name} />}
        {seo.opengraphTitle && (
          <meta
            property="og:title"
            content={htmlEntities.decode(seo.opengraphTitle)}
          />
        )}
        {seo.opengraphDescription && (
          <meta
            property="og:description"
            content={htmlEntities.decode(seo.opengraphDescription)}
          />
        )}
        {seo.opengraphTitle && (
          <meta property="og:type" content={seo.opengraphTitle} />
        )}
        {seo.opengraphUrl && (
          <meta property="og:url" content={convertUrl(seo.opengraphUrl)} />
        )}
        {seo.opengraphImage && (
          <meta
            property="og:image"
            content={seo.opengraphImage && seo.opengraphImage.sourceUrl}
          />
        )}
        <meta property="og:locale" content={lang} />
        {seo.twitterTitle && (
          <meta name="twitter:title" content={seo.twitterTitle} />
        )}
        {seo.twitterDescription && (
          <meta name="twitter:description" content={seo.twitterDescription} />
        )}
        {seo.twitterImage && (
          <meta name="twitter:image" content={seo.twitterImage} />
        )}
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
    </>
  );
};

export default SEO;
