import React, { useState } from "react";
import styled from "@emotion/styled";
import { theme } from "../../Theme";
import { invertColor } from "../../Util/Helpers";
import { useFetch } from "../../Util/Hooks/useFetch";
import { ShirtIcon } from "./Shirt";
import { Ball } from "./Ball";
import RightIcon from "../../ui/icons/RightIcon";
import { useHistory, useLocation } from "react-router-dom";
import { PlayerPopup } from "./PlayerPopup";
import { config } from "../../../config";

const teamFieldHeight = 500;
const lineColor = "#FFF";

const LineupWrapper = styled.div``;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #539a46;
  padding: 3px;
  position: relative;
  height: ${teamFieldHeight * 2}px;
  .line,
  .half,
  .penalty,
  .center {
    opacity: 0.3;
  }
  .line {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    border: 3px solid ${lineColor};
  }
  .half {
    width: 100%;
    height: 3px;
    background: ${lineColor};
    position: absolute;
    left: 0px;
    right: 0px;
    top: ${teamFieldHeight}px;
    bottom: 0px;
  }
  .penalty {
    border: 3px solid ${lineColor};
    position: absolute;
    width: 45%;
    height: 15%;
    left: 27%;
    &.top {
      top: 0;
    }
    &.bottom {
      bottom: 0;
    }
  }
  .center {
    position: absolute;
    width: 25%;
    height: 20%;
    border: 3px solid ${lineColor};
    left: 37.5%;
    top: 40%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
  }
`;

const TeamWrapper = styled.div``;

const TeamField = styled.div`
  height: ${teamFieldHeight}px;
  width: 100%;
  padding-bottom: 2rem;
  position: relative;
  .field-inner {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .team-logo {
    position: absolute;
    left: 5px;
    width: 50px;
    height: 50px;
  }
  &:not(.away) .team-logo {
    bottom: 5px;
  }
  &.away .team-logo {
    top: 20px;
  }
`;

const PlayerWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  left: calc(${(props) => props.xpos}% - 30px);
  top: calc(${(props) => props.ypos}% - 20px);
  display: flex;
  flex-direction: column;
  &.no-pos {
    top: 0;
    left: 0;
    position: relative;
    float: left;
    margin: 2rem;
    margin-top: 15%;
  }
  .number {
    margin: 0;
    position: absolute;
    top: 18%;
    left: 51%;
    font-size: 1.2rem;
    transform: translateX(-50%) translateY(0);
    color: ${(props) => props.numberColor};
  }
  .name {
    line-height: 1;
    position: absolute;
    top: 53px;
    left: -22px;
    width: 100px;
    text-align: center;
    font-size: 0.9rem;
    color: ${theme.colors.white};
    text-shadow: 1px 1px 2px #00000087;
  }
  .position {
    display: none;
  }
  .captain-badge {
    position: absolute;
    background: #000;
    color: white;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 9px;
  }
  .event-wrapper {
    .goal {
      position: absolute;
      top: -5px;
      right: -5px;
      font-weight: bold;
      svg {
        color: #000;
        font-size: 14px;
        margin-right: 3px;
      }
    }
    .card-wrapper {
      position: absolute;
      top: -5px;
      right: -5px;
      .card {
        display: block;
        width: 5px;
        height: 10px;
        background: yellow;
        margin: 0 2px;
        &.red {
          background: red;
        }
      }
    }
    .subbed {
      position: absolute;
      top: 20px;
      right: 0;
      background: white;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 8px;
        transform: rotate(90deg);
        color: red;
      }
      .in {
        svg {
          color: green;
          transform: rotate(-90deg);
        }
      }
    }
  }
  > svg {
    font-size: 3rem;
    color: ${(props) => props.shirtColor};
  }
`;

const BenchField = styled.div`
  display: flex;
  padding: 1rem;
  background-color: #437b39;
  ${theme.below.sm} {
    flex-direction: column;
  }
`;

const BenchWrapper = styled.div`
  width: 50%;
  ${theme.below.sm} {
    width: 100%;
  }
  h3 {
    color: ${theme.colors.white};
    display: flex;
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }
  .coach {
    color: ${theme.colors.white};
  }
  div.player {
    top: 0;
    left: 0;
    position: relative;
    float: none;
    margin: 10px 0;
    flex-direction: row;
    align-items: baseline;
    svg {
      font-size: 2rem;
    }
    .number {
      top: 19%;
      left: 19px;
      font-size: 0.9rem;
    }
    .name {
      line-height: 1;
      position: static;
      top: 0;
      left: 0;
      width: auto;
      text-align: left;
      font-size: 1rem;
      margin-left: 0.7rem;
    }
    .position {
      display: block;
      margin-left: 5px;
      font-size: 0.8rem;
      color: #eee;
      &:before {
        content: "(";
      }
      &:after {
        content: ")";
      }
    }
  }
`;

const Events = ({ player, events }) => {
  if (!events) return null;

  const goals = events.filter((event) => {
    return event.type === "GOAL" && event.primaryActor.person.id === player.id;
  });
  const ycards = events.filter((event) => {
    return (
      event.type === "YELLOW_CARD" && event.primaryActor.person.id === player.id
    );
  });
  const rcards = events.filter((event) => {
    return (
      event.type === "RED_CARD" && event.primaryActor.person.id === player.id
    );
  });
  const subbed = events.filter((event) => {
    return (
      event.type === "SUBSTITUTION" &&
      (event.primaryActor.person.id === player.id ||
        event.secondaryActor.person.id === player.id)
    );
  });
  return (
    <div className="event-wrapper">
      {goals.length > 0 && (
        <span className="goal">
          <Ball />
          {goals.length > 1 && goals.length}
        </span>
      )}
      {(ycards.length > 0 || rcards.length > 0) && (
        <span className="card-wrapper">
          {ycards.map(() => (
            <span className="card" />
          ))}
          {rcards.map(() => (
            <span className="card red" />
          ))}
        </span>
      )}
      {subbed.length > 0 && (
        <span
          className={`subbed ${
            subbed[0].primaryActor.person === player.id ? "out" : "in"
          }`}
        >
          <RightIcon />
        </span>
      )}
    </div>
  );
};

const Player = ({ player, team, away, count }) => {
  const [popup, setPopup] = useState(false);

  if (!player.fieldCoordinate) count += 8;
  const xpos = player.fieldCoordinate ? player.fieldCoordinate.x / 10 : count;
  const ypos = player.fieldCoordinate
    ? (player.fieldCoordinate.y * 1.1) / 10
    : count;
  return (
    <>
      <PlayerWrapper
        className={`player ${player.fieldCoordinate ? "" : "no-pos"}`}
        xpos={away ? 100 - xpos : xpos}
        ypos={away ? 100 - ypos : ypos}
        shirtColor={team.shirtColor}
        numberColor={invertColor(team.shirtColor)}
        onClick={() => {
          setPopup(true);
        }}
      >
        {player.type === "CAPTAIN" && <span className="captain-badge">C</span>}
        <ShirtIcon className="icon" />
        <h5 className="number">{player.jerseyNumber}</h5>
        <h5 className="name">{player.player.translations.lastName.EN}</h5>
        <span className="position">{player.player.fieldPosition}</span>
      </PlayerWrapper>
      {popup && (
        <PlayerPopup
          player={player}
          close={() => {
            setPopup(false);
          }}
        />
      )}
    </>
  );
};

const Team = ({ team, away }) => {
  let count = 0;
  if (team)
    return (
      <TeamWrapper>
        <TeamField className={`team-field ${away ? "away" : ""}`}>
          <div className="field-inner">
            <img
              className="team-logo"
              src={team.team.logoUrl}
              alt={team.team.internationalName}
            />
            {team.field.map((player, index) => {
              return (
                <Player
                  key={index}
                  player={player}
                  count={count}
                  away={away}
                  team={team}
                />
              );
            })}
          </div>
        </TeamField>
      </TeamWrapper>
    );
  else return false;
};

const Bench = ({ team, away }) => {
  if (team) {
    const coach =
      team.coaches && team.coaches.length
        ? team.coaches[0].person.translations.name.EN
        : false;
    return (
      <BenchWrapper>
        <h3>
          <img
            className="team-logo"
            src={team.team.logoUrl}
            alt={team.team.internationalName}
          />
          {team.team.internationalName}
        </h3>
        {coach && (
          <span className="coach">
            Coach: <span>{coach}</span>
          </span>
        )}

        {team.bench.map((player, index) => (
          <Player
            key={index}
            player={player}
            away={away}
            count={0}
            team={team}
          />
        ))}
      </BenchWrapper>
    );
  } else return false;
};

const LINEUP_URL = (id) => `${config.apiEndpoint}matches/${id}/lineups`;

export const Lineup = ({ id, showPlayer = true }) => {
  const { data, status } = useFetch(LINEUP_URL(id));
  const ready = status === "fetched" && data;
  return (
    <LineupWrapper>
      <Field>
        <div className="line" />
        <div className="half" />
        <div className="penalty top" />
        <div className="penalty bottom" />
        <div className="center" />
        {ready && (
          <>
            <Team team={data.homeTeam} showPlayer={showPlayer} />
            <Team team={data.awayTeam} showPlayer={showPlayer} away={true} />
          </>
        )}
      </Field>
      <BenchField>
        {ready && (
          <>
            <Bench team={data.homeTeam} showPlayer={showPlayer} />
            <Bench team={data.awayTeam} showPlayer={showPlayer} away={true} />
          </>
        )}
      </BenchField>
    </LineupWrapper>
  );
};
