import React from "react";
import styled from "@emotion/styled";
import SpinnerIcon from "./icons/SpinnerIcon";

const LoadingPageWrapper = styled("div")`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  padding: 20% 2rem 1rem;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
  svg {
    color: #444;
    font-size: 5rem;
    animation: spin 2s linear infinite;
  }
`;
export const LoadingPage = () => {
  return (
    <LoadingPageWrapper>
      <SpinnerIcon />
    </LoadingPageWrapper>
  );
};
