import React from "react";
import styled from "@emotion/styled";
import { custom } from "./styling/custom";
import { image } from "./styling/image";
import { cover } from "./styling/cover";
import { columns } from "./styling/columns";
import { gallery } from "./styling/gallery";
import { mediaText } from "./styling/media-text";
import { text } from "./styling/text";
import { table } from "./styling/table";

const StylesWrapper = styled.div`
  ${text}
  ${custom}
  ${image}
  ${cover}
  ${columns}
  ${gallery}
  ${mediaText}
  ${table}
`;

export const BlockStyles = ({ children }) => {
  return <StylesWrapper className="styles-wrapper">{children}</StylesWrapper>;
};
