import React from "react";
import styled from "@emotion/styled";
import { MaxWidthText } from "../Layout/MaxWidth";
import { dateFormat } from "../Util/Helpers";
import { theme } from "../Theme";
import { useFetch } from "../Util/Hooks/useFetch";
import { Lineup } from "./Lineup/Lineup";
import { Ball } from "./Lineup/Ball";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltDown, faArrowAltUp } from "@fortawesome/pro-light-svg-icons";
import { config } from "../../config";

const MatchWrapper = styled.div`
  padding-bottom: 3rem;
`;

const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.2rem 0;
  ${theme.below.sm} {
    padding: 1rem 0;
    flex-direction: column;
    div.group-date,
    span.stadium-name {
      text-align: center;
      width: 100%;
      margin: 5px 0;
    }
    span.stadium-name {
      margin-top: 30px;
    }
  }
  .meta {
    text-align: center;
  }
  .group-date {
    display: flex;
    flex-direction: column;
    .time {
      margin: 4px 0;
    }
  }
  .group-date,
  .stadium-name {
  }
  .stadium-name {
    text-align: right;
  }
  .teams-wrapper {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin-top: 2rem;
    ${theme.below.xs} {
      flex-wrap: wrap;
      justify-content: space-between;
      .result {
        order: -1;
        width: 100%;
        margin-bottom: 3rem;
        justify-content: center;
      }
      .team {
        width: 46%;
        .events-list {
          .name {
            .out {
              display: block;
            }
            .in {
              margin: 0;
            }
          }
        }
      }
    }
  }
  .team {
    display: flex;
    align-items: flex-start;
    width: 50%;
    ${theme.below.sm} {
      flex-direction: column;
      .team-info {
        order: 1;
      }
      &:not(.away) {
        align-items: flex-end;
      }
    }
    &:not(.away) {
      justify-content: flex-end;
      .team-info {
        text-align: right;
      }
    }
    &.away {
      .team-info {
        ${theme.above.md} {
          order: 1;
        }
      }
    }
    .team-info {
      padding: 1rem 1rem;
      ${theme.below.sm} {
        padding: 1rem 0;
      }
      .name {
        ${theme.above.sm} {
          font-size: 1.6rem;
          line-height: 1;
        }
        ${theme.above.md} {
          font-size: 2rem;
        }
      }
      .events-list {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        svg {
          font-size: 9px;
          margin-right: 5px;
        }
        > span {
          margin: 4px 0;
        }
        span {
          font-size: 0.8rem;
          text-transform: uppercase;
        }
        .minute {
          margin-left: 5px;
        }
        .out {
          display: block;
          svg {
            color: red;
          }
        }
        .in {
          svg {
            color: green;
          }
        }
        .YELLOW_CARD,
        .RED_CARD {
          &:before {
            margin-right: 5px;
            content: "";
            width: 7px;
            height: 10px;
            display: inline-block;
            background: #f7e50d;
          }
        }
        .RED_CARD {
          &:before {
            background: red;
          }
        }
      }
    }
    .name {
      margin: 0;
    }
    img {
      margin: 0 0.5rem;
      ${theme.below.sm} {
        margin: 0;
      }
      width: 70px;
      height: 70px;
      ${theme.below.sm} {
        width: 50px;
        height: 50px;
      }
    }
  }
  .result {
    padding: 1rem 1rem;
    margin: 0 1rem;
    display: flex;
    align-items: center;
    position: relative;
    > a {
      display: flex;
      align-items: center;
    }
    &[data-status="UPCOMING"] {
      .status-value {
        display: none;
      }
    }
    &[data-status="LIVE"] {
      background: #fbd72a;
      .status-value {
        color: ${theme.colors.font};
      }
    }
    &[data-status="UPCOMING"] {
    }
    .status-wrapper {
      position: absolute;
      width: 100%;
      text-align: center;
      top: 55px;
      left: 0;
      font-size: 0.8rem;
    }
    h3 {
      margin: 0;
    }
    .seperator {
      margin: 0 5px;
    }
  }
`;

const Result = ({ match }) => {
  const time = new Date(match.kickOffTime.dateTime);
  const gameTime = dateFormat(time);
  return (
    <div className="result" data-status={match.status}>
      {match.status === "UPCOMING" ? (
        <>
          <h5>{gameTime.time}</h5>
        </>
      ) : (
        <>
          {match.score && (
            <>
              <h3>{match.score.total.home}</h3>
              <span className="seperator">-</span>
              <h3>{match.score.total.away}</h3>
            </>
          )}
        </>
      )}
      <div className="status-wrapper">
        <div className="status-value">
          {match.status === "LIVE"
            ? match.minute
              ? match.minute.normal + "'"
              : match.translations.phaseName.EN
            : match.status}
        </div>
      </div>
    </div>
  );
};

const Team = ({ team, away, events }) => {
  return (
    <div className={`team ${away ? "away" : ""}`}>
      <div className="team-info">
        <h5 className="name">{team.internationalName}</h5>
        {events && (
          <div className="events-list">
            {events.map((event, index) => {
              return (
                <span key={index} className={event.type}>
                  {(event.type === "GOAL" ||
                    event.type === "OWN_GOAL" ||
                    event.type === "PENALTY_GOAL") && <Ball />}
                  <span className="name">
                    {event.type === "SUBSTITUTION" ? (
                      <>
                        <span className="out">
                          <FontAwesomeIcon icon={faArrowAltDown} />
                          {
                            event.primaryActor?.person?.translations?.shortName
                              .EN
                          }
                        </span>
                        <span className="in">
                          <FontAwesomeIcon icon={faArrowAltUp} />
                          {
                            event.secondaryActor?.person?.translations
                              ?.shortName.EN
                          }
                        </span>
                      </>
                    ) : (
                      event.primaryActor?.person?.translations?.shortName.EN
                    )}
                  </span>
                  <span className="minute">{event.time.minute}'</span>
                </span>
              );
            })}
          </div>
        )}
      </div>
      <img src={team.logoUrl} alt={team.internationalName} />
    </div>
  );
};

function getEvent(events, id) {
  return events
    .filter((event) => {
      return (
        event.primaryActor?.team.id === id ||
        event.secondaryActor?.team.id === id
      );
    })
    .reverse();
}

const MatchHeader = ({ match, events }) => {
  const time = dateFormat(match.kickOffTime.dateTime);
  const homeEvents = events ? getEvent(events, match.homeTeam.id) : false;
  const awayEvents = events ? getEvent(events, match.awayTeam.id) : false;
  return (
    <HeaderWrapper>
      <div className="meta">
        <span className="stadium-name">
          {match.stadium?.metaData?.officialName}
        </span>
        <div className="group-date">
          <span className="date">
            {time.date} {time.time}
          </span>
          <span className="group-name">{match.group?.metaData?.groupName}</span>
        </div>
      </div>
      <div className="teams-wrapper">
        <Team team={match.homeTeam} events={homeEvents} />
        <Result match={match} />
        <Team team={match.awayTeam} away={true} events={awayEvents} />
      </div>
    </HeaderWrapper>
  );
};

const MATCH_ID_URL = (id) =>
  `${config.apiEndpoint}matches?matchId=${id}&offset=0&limit=1`;
const EVENT_URL = (id) =>
  `${config.apiEndpoint}matches/${id}/events?filter=LINEUP&offset=0&limit=50`;
export const Match = (props) => {
  const id = props.match.params.id;
  const matchData = useFetch(MATCH_ID_URL(id));
  const eventData = useFetch(EVENT_URL(id));
  const match =
    matchData.status === "fetched"
      ? matchData.data.length && matchData.data[0]
      : false;

  const events =
    eventData.status === "fetched" && match ? eventData.data : false;

  if (match) {
    return (
      <MaxWidthText>
        <MatchWrapper>
          <MatchHeader match={match} events={events} />
          <Lineup id={id} />
        </MatchWrapper>
      </MaxWidthText>
    );
  } else return null;
};
