import React from "react";
import { Query } from "react-apollo";
import styled from "@emotion/styled";
import { LoadingPage } from "../ui/LoadingPage";
import SEO from "../SEO/SEO";
import { BlockContent } from "../Blocks/BlockContent";
import InnerContent from "../Layout/Content/InnerContent";
import startPageQuery from "./StartPageQuery.gql";
import { getFontSize, theme } from "../Theme";

const StartPageWrapper = styled.div`
  h1 {
    ${getFontSize(theme.startPage.headers.fontSize[1])}
  }
  h2 {
    ${getFontSize(theme.startPage.headers.fontSize[2])}
  }
  h3 {
    ${getFontSize(theme.startPage.headers.fontSize[3])}
  }
  h4 {
    ${getFontSize(theme.startPage.headers.fontSize[4])}
  }
  h5 {
    ${getFontSize(theme.startPage.headers.fontSize[5])}
  }
  p {
    max-width: 600px;
    &.has-text-align-center {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const StartPage = () => {
  return (
    <Query query={startPageQuery}>
      {({ data, loading }) => {
        const startpage = data && data.page;
        if (loading || !startpage) {
          return <LoadingPage />;
        }
        return (
          <StartPageWrapper>
            <SEO seo={startpage.seo} />
            <InnerContent>
              <BlockContent
                content={startpage.content}
                className="full-screen"
              />
            </InnerContent>
          </StartPageWrapper>
        );
      }}
    </Query>
  );
};

export default StartPage;
