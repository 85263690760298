import { css } from "@emotion/core";
import { theme } from "../../Theme";

export const gallery = css`
  ul.wp-block-gallery {
    margin-bottom: 0;
    margin-left: 0;
    padding-left: 0;
    ${theme.above.lg} {
    }
    li.blocks-gallery-item {
      margin: 0 $block-gallery-space 0 0;
      width: calc((100% - #{$block-gallery-space}) / 2);
      justify-content: flex-start;
      figure {
        height: auto;
      }
      ${theme.below.sm} {
        &:nth-of-type(even) {
          //margin-right: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      ${theme.above.md} {
        margin-bottom: 0;
      }
    }
    &.columns-3 {
      li.blocks-gallery-item {
        ${theme.below.sm} {
          &:nth-of-type(1),
          &:nth-of-type(2) {
            margin-bottom: $block-gallery-space;
          }
        }
        @media (min-width: 600px) {
          margin-right: $block-gallery-space;
          width: calc((100% - #{$block-gallery-space}* 2) / 3);
        }
      }
    }
    &.columns-4 {
      li.blocks-gallery-item {
        ${theme.below.sm} {
          &:nth-of-type(1),
          &:nth-of-type(2) {
            margin-bottom: $block-gallery-space;
          }
        }
        ${theme.above.md} {
          width: calc((100% - #{$block-gallery-space}* 3) / 4);
        }
      }
    }
  }

  .gallery-padding {
    padding: $block-gallery-space !important;
  }

  .gallery-padding-side {
    padding: 0 $block-gallery-space !important;
  }
`;
