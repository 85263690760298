import React from "react";
import styled from "@emotion/styled";
import { ShutterLink } from "../../ui/ShutterLink";
import RightIcon from "../../ui/icons/RightIcon";
import { scrollBar } from "../../GlobalStyles/helpers";
import { theme } from "../../Theme";

const BreadcrumbWrapper = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  //GENERAL
  > ul {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    ${scrollBar}
    > li {
      text-transform: uppercase;
      white-space: nowrap;
    }
  }

  //BLOCK STYLE
  .block {
    list-style: none;
    overflow: hidden;
    margin-top: 8px;
  }
  .block li a {
    text-decoration: none;
    padding: 8px 0 8px 50px;
    background: #dedede;
    position: relative;
    display: block;
    font-size: 0.8rem;
  }
  .block li:last-child a {
    background: #6e6e6e;
    pointer-events: none;
    cursor: default;
    color: white;
  }
  .block li:last-child a::after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 50px solid transparent; /* Go big on the size, and let overflow hide */
    border-bottom: 50px solid transparent;
    border-left: 30px solid #6e6e6e;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    left: 100%;
    z-index: 2;
  }
  .block li a::after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 50px solid transparent; /* Go big on the size, and let overflow hide */
    border-bottom: 50px solid transparent;
    border-left: 30px solid #dedede;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    left: 100%;
    z-index: 2;
  }
  .block li a::before {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 31px solid white;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    margin-left: 1px;
    left: 100%;
    z-index: 1;
  }
  .block li:first-child a {
    padding-left: 20px;
  }
  .block li a:hover {
    background: #b3b3b3;
  }
  .block li a:hover:after {
    border-left-color: #b3b3b3 !important;
  }
  .block li:last-child a:hover {
    background: #000000;
  }
  .block li:last-child a:hover:after {
    border-left-color: #000000 !important;
  }

  .blue {
    margin-top: 5px;
    list-style-type: none;
  }
  .blue li {
    float: left;
    color: #d6d6c9;
  }
  .blue li:last-child a {
    color: #34ebe8;
    text-decoration: underline;
    font-weight: bold;
    pointer-events: none;
    cursor: default;
  }
  .blue li a:hover {
    text-decoration: underline;
  }
  .blue li + li:before {
    padding: 8px;
    content: ">";
    color: #34ebe8;
    font-size: 15px;
  }

  .standard {
    font-size: 0.8rem;
    li {
      display: flex;
      align-items: center;
      a {
        &::hover {
          text-decoration: underline;
        }
      }
      &:last-child {
        span {
          //color: ${theme.colors.primary};
        }
      }
    }
    li + li:before {
      margin: 0 10px;
      content: "/";
      color: #666;
      font-size: 10px;
    }
  }
`;

const BackButton = styled.button`
  text-transform: uppercase;
  font-size: 0.8rem;
  padding: 4px 10px;
  margin-top: 5px;
  display: inline-block;
  border: 1px solid #eee;
  svg {
    transform: rotate(180deg);
    font-size: 0.5rem;
    margin-right: 5px;
  }
`;

export const Breadcrumbs = ({ data, showBackButton = false }) => {
  const backItem = data.length > 2 ? data[data.length - 2] : false;
  return (
    <BreadcrumbWrapper className="breadcrumbs-wrapper">
      {/* Choose between 3 different breadcrumb styles:
          - block,
          - blue,
          - standard
      */}
      <ul className="standard">
        {data.map((crumb, index) => (
          <li key={index}>
            {data.length === index + 1 ? (
              <span dangerouslySetInnerHTML={{ __html: crumb.text }} />
            ) : (
              <ShutterLink to={crumb.url}>
                <span dangerouslySetInnerHTML={{ __html: crumb.text }} />
              </ShutterLink>
            )}
          </li>
        ))}
      </ul>
      {backItem && showBackButton && (
        <BackButton>
          <ShutterLink to={backItem.url}>
            <RightIcon />
            <span>Tillbaka</span>
          </ShutterLink>
        </BackButton>
      )}
    </BreadcrumbWrapper>
  );
};
