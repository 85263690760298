import { css } from "@emotion/core";
import { theme } from "../../Theme";

export const text = css`
  .block-wrapper {
    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6,
    > p,
    > ul {
      margin: 1em auto 0.5em auto;
    }

    > h1 {
      font-size: 3rem;
      ${theme.above.lg} {
        font-size: 5rem;
      }
    }
    > h2 {
      font-size: 2.2rem;
      ${theme.above.lg} {
        font-size: 4rem;
      }
    }
    > h3 {
      font-size: 1.8rem;
      ${theme.above.lg} {
        font-size: 3rem;
      }
    }
    > h4 {
      font-size: 1.6rem;
      ${theme.above.lg} {
        font-size: 2rem;
      }
    }
    > h5 {
      font-size: 1.4rem;
      ${theme.above.lg} {
        font-size: 1.8rem;
      }
    }
    > h6 {
      font-size: 1.2rem;
      ${theme.above.lg} {
        font-size: 1.6rem;
      }
    }
    > ul {
      li {
        display: flex;
        align-items: center;
        &:before {
          content: "\u2B24";
          margin-right: 10px;
          font-size: 0.5rem;
          display: inline-block;
          color: ${theme.colors.primary};
        }
      }
    }
  }
`;
