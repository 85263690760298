import React from "react";
import styled from "@emotion/styled";
import Image from "../ui/Image";
import { LoadingPage } from "../ui/LoadingPage";

const MediaWrapper = styled.article`
  padding-bottom: 3rem;
`;

const MediaItem = ({ data, loading }) => {
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <MediaWrapper>
      <Image image={data} />
    </MediaWrapper>
  );
};

export default MediaItem;
