
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"MediaItemFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"MediaItem"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mediaType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"caption"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"srcSet"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"sizes"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"sourceUrl"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"altText"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"title"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":136}};
    doc.loc.source = {"body":"fragment MediaItemFragment on MediaItem {\r\n    mediaType\r\n    caption\r\n    srcSet\r\n    sizes\r\n    sourceUrl\r\n    altText\r\n    title\r\n}\r\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
