import { css } from "@emotion/core";
import { theme } from "../../Theme";

export const cover = css`
  .wp-block-cover {
    padding-top: 70%;
    .wp-block-cover__inner-container,
    .wp-block-cover-text {
      position: absolute;
      width: 100%;
      left: 50%;
      top: 50%;
      padding: ${theme.content.textPadding.sm};
      max-width: ${theme.general.maxWidth};
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .wp-block-cover.bottom {
    .wp-block-cover__inner-container {
      bottom: 0;
      top: auto;
      transform: translateX(-50%) translateY(0);
    }
  }

  //CUSTOM CLASSES
  .white-text {
    * {
      color: white !important;
    }
  }
`;
