import React from "react";
import { Query } from "react-apollo";
import styled from "@emotion/styled";
import { config } from "../../config";
import postsQuery from "./PostsQuery.gql";
import { ScrollSlider } from "../ui/ScrollSlider";
import { ShutterLink } from "../ui/ShutterLink";
import Image from "../ui/Image";
import { PostMeta } from "../Post/PostMeta";

const WidgetWrapper = styled.div``;
const ItemWrapper = styled.article``;
const ItemImage = styled.div`
  width: 100%;
  a,
  img {
    display: block;
  }
`;
const Excerpt = styled.p`
  max-height: 102px;
  overflow: hidden;
  margin-bottom: 1rem;
  line-height: 1.5;
`;
const Title = styled.h5`
  margin: 0.5rem 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const TextWrapper = styled.div``;
const ContentWrapper = styled.div`
  padding: 0.3rem 1rem;
`;

//BLOG ITEMS COMPONENT
export const WidgetItem = ({ node }) => {
  const postUrl = node.uri;
  return (
    <ItemWrapper className="item-card">
      {node.featuredImage && node.featuredImage.node && (
        <ItemImage>
          <ShutterLink to={postUrl}>
            <Image
              image={node.featuredImage.node}
              sizes={[100, 50, 33, 33, 33]}
              heights={[260, 300, 400]}
              crop={true}
            />
          </ShutterLink>
        </ItemImage>
      )}
      <ContentWrapper>
        <ShutterLink to={postUrl}>
          <Title>{node.title}</Title>
        </ShutterLink>
        <TextWrapper>
          <Excerpt
            dangerouslySetInnerHTML={{
              __html: node.excerpt,
            }}
          />
        </TextWrapper>
        <PostMeta post={node} />
      </ContentWrapper>
    </ItemWrapper>
  );
};

const BlogWidget = ({
  maxNumberOfPosts = config.routes.blog.maxPosts,
  selectedCategory = null,
}) => {
  return (
    <Query
      query={postsQuery}
      variables={{
        category: selectedCategory,
        first: maxNumberOfPosts,
      }}
    >
      {({ loading, error, data }) => {
        if (loading || !data) return null;
        if (error) return null;
        return (
          <WidgetWrapper>
            <ScrollSlider>
              {data.posts.nodes.map((node, index) => {
                return <WidgetItem key={index} node={node} />;
              })}
            </ScrollSlider>
          </WidgetWrapper>
        );
      }}
    </Query>
  );
};

export default BlogWidget;
