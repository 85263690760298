import React, { useEffect } from "react";

function loadStyleSheet(href, integrity) {
  let sheet = document.createElement("link");
  sheet.rel = "stylesheet";
  sheet.href = href;
  sheet.integrity = integrity;
  sheet.type = "text/css";
  sheet.crossOrigin = "anonymous";
  document.head.appendChild(sheet);
}

const TypeIcon = () => {
  useEffect(() => {
    if (document) {
      loadStyleSheet(
        "https://pro.fontawesome.com/releases/v5.14.0/css/light.css",
        "sha384-LmKkPHDqucxgmrtLKWrMGZc5nnHQYAdFkSzMtl1OcvTZn4pebmVziSZPtp27MA6u"
      );
      loadStyleSheet(
        "https://pro.fontawesome.com/releases/v5.14.0/css/fontawesome.css",
        "sha384-CAxg0L30Vie2vI3AniQ4UA+pSswoJmr/MK5Dl5DP9YlE1nzJn4z5updw5S3i/Nsn"
      );
    }
  });
  return null;
};

export default TypeIcon;
