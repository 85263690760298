import React from "react";
import styled from "@emotion/styled";
import useFetch from "use-http";
import { MatchList } from "../MatchList/MatchList";
import { config } from "../../config";

const Wrapper = styled.div`
  position: relative;
`;

const MATCHES_PREV_URL = `${config.apiEndpoint}matches?&offset=0&limit=16&competitionId=${config.competitionId}&seasonYear=${config.year}&phase=TOURNAMENT&status=FINISHED`;

export const LatestResult = () => {
  const { data } = useFetch(MATCHES_PREV_URL, {}, []);
  if (data && data.length)
    return (
      <Wrapper>
        <MatchList matchData={data} />
      </Wrapper>
    );
  return null;
};
