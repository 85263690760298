import React, { useEffect } from "react";
import styled from "@emotion/styled";
import CrossIcon from "./icons/CrossIcon";

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  padding: 5%;
  display: flex;
  justify-content: center;
`;

const ModalContent = styled.div`
  position: relative;
  height: 100%;
  overflow-x: auto;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  svg {
    color: black;
    font-size: 2rem;
  }
`;

const ModalBackground = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Modal = ({
  children,
  close = () => {},
  className = "",
  lockBackground = false,
}) => {
  useEffect(() => {
    if (lockBackground) {
      const html = document.getElementsByTagName("html")[0];
      html.setAttribute("style", "overflow:hidden");
      return () => {
        html.removeAttribute("style");
      };
    }
  });
  return (
    <>
      <ModalWrapper className={`modal-wrapper ${className}`}>
        <ModalContent className="modal-content">
          <CloseButton
            className="close-modal"
            onClick={() => {
              close();
            }}
          >
            <CrossIcon />
          </CloseButton>
          {children}
        </ModalContent>
      </ModalWrapper>
      <ModalBackground
        className="modal-background"
        onClick={() => {
          close();
        }}
      />
    </>
  );
};
