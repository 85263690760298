import React from "react";
import styled from "@emotion/styled";
import { theme } from "../Theme";

export const MaxWidthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  margin: 0 auto;
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  max-width: ${theme.general.maxWidth};
  padding: 0 ${theme.general.pagePadding.md};
  ${theme.above.md} {
    padding: 0 ${theme.general.pagePadding.lg};
  }
  &.text {
    max-width: ${theme.posts.maxWidth};
  }
`;

export const Inner = styled.div`
  position: relative;
`;

export const PagePaddingWrapper = styled.div`
  padding: 0 ${theme.general.pagePadding.md};
  ${theme.above.md} {
    padding: 0 ${theme.general.pagePadding.lg};
  }
`;

export const PagePadding = ({ children }) => {
  return (
    <PagePaddingWrapper className="page-padding">{children}</PagePaddingWrapper>
  );
};

const MaxWidth = ({ className = "", children }) => {
  return (
    <MaxWidthWrapper className={`max-width-wrapper ${className}`}>
      {children}
    </MaxWidthWrapper>
  );
};

export const MaxWidthText = ({ className = "", children }) => {
  return (
    <MaxWidthWrapper className={`max-width-wrapper text ${className}`}>
      {children}
    </MaxWidthWrapper>
  );
};

export default MaxWidth;
