import React, { useState } from "react";
import { Query } from "react-apollo";
import styled from "@emotion/styled";
import { ListItem } from "./BlogListItem";
import { LoadingBox } from "../ui/LoadingState";
import { config } from "../../config";
import postsQuery from "./PostsQuery.gql";
import { Button } from "../ui/Button";

const BlogListWrapper = styled.section``;

const LoadMoreWrapper = styled.div`
  margin: 2rem 0 3rem;
  text-align: center;
`;

const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    margin: 1rem 0;
  }
`;

const BlogListLoadingState = () => {
  return (
    <BoxWrapper>
      <LoadingBox />
      <LoadingBox />
      <LoadingBox />
      <LoadingBox />
      <LoadingBox />
      <LoadingBox />
    </BoxWrapper>
  );
};

export const BlogList = ({
  maxNumberOfPosts = config.routes.blog.maxPosts,
  selectedCategory = null,
  after = null,
  page = 1,
  ...props
}) => {
  const urlQuery = new URLSearchParams(props.location.search);
  const currentPage = parseInt(urlQuery.get("page"));
  const initalLoad = currentPage > 1 && page <= currentPage;
  const [load, setLoad] = useState(initalLoad);
  return (
    <Query
      query={postsQuery}
      variables={{
        category: selectedCategory,
        first: maxNumberOfPosts,
        after: after,
      }}
    >
      {({ loading, error, data }) => {
        if (loading || !data) return <BlogListLoadingState />;

        if (error) return null;
        let hasNextPage = false;
        let lastPostCursor = null;
        if (data.posts.pageInfo != null) {
          hasNextPage = data.posts.pageInfo.hasNextPage;
          if (data.posts.pageInfo.endCursor) {
            lastPostCursor = data.posts.pageInfo.endCursor;
          }
        }
        return (
          <>
            <BlogListWrapper>
              {data.posts.nodes.map((node, index) => {
                return <ListItem key={index} node={node} />;
              })}
              {hasNextPage && !load && (
                <LoadMoreWrapper>
                  <Button
                    onClick={() => {
                      props.history.push(
                        props.location.pathname + "?page=" + (page + 1)
                      );
                      setLoad(true);
                    }}
                  >
                    Visa fler
                  </Button>
                </LoadMoreWrapper>
              )}
            </BlogListWrapper>
            {hasNextPage && load && (
              <BlogList
                selectedCategory={selectedCategory}
                after={lastPostCursor}
                page={page + 1}
                {...props}
              />
            )}
          </>
        );
      }}
    </Query>
  );
};
