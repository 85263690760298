import React from "react";
import styled from "@emotion/styled";
import { theme } from "../../../Theme";
import { ShutterLink } from "../../../ui/ShutterLink";
import { useActiveMenuItem } from "../helpers";

const SubMenuWrapper = styled.div`
  position: absolute;
  top: ${theme.desktopHeader.height.lg - 1 + "px"};
  width: 260px;
  left: -2rem;
  padding: 1rem 2rem;
  border: 1px solid ${theme.colors.border};
  background: white;
  &[data-scroll="true"] {
    top: ${theme.desktopHeader.height.scroll - 1 + "px"};
  }
  > ul {
    > li {
      display: block;
      margin: 8px 0;
      > a {
        font-size: 0.9rem;
      }
    }
  }
`;

const SubMenuItem = ({ item, setShowMenu }) => {
  const active = useActiveMenuItem(item.connectedObject.uri);
  return (
    <li className={active ? active : ""}>
      <button>
        <ShutterLink
          to={item.connectedObject.uri}
          onClick={() => {
            setShowMenu(false);
          }}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: item.label,
            }}
          />
        </ShutterLink>
      </button>
    </li>
  );
};

export const DesktopSubMenu = ({ item, setShowMenu, scrolling }) => {
  return (
    <SubMenuWrapper data-scroll={scrolling}>
      <ul>
        {item.children.map((subItem) => {
          return (
            <SubMenuItem
              key={subItem.id}
              item={subItem}
              setShowMenu={setShowMenu}
            />
          );
        })}
      </ul>
    </SubMenuWrapper>
  );
};
