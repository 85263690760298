import React from "react";
import styled from "@emotion/styled";
import { theme } from "../../Theme";
import { Lineup } from "./Lineup";
import { Modal } from "../../ui/Modal";

const StyledModal = styled(Modal)`
  padding: 0;
  @media screen and (min-width: 800px) {
    padding: 2%;
  }
  .modal-content {
    max-width: 800px;
    width: 100%;
    position: static;
    .close-modal {
      top: 10px;
      right: 30px;
    }
  }
`;

const PopupWrapper = styled.div`
  background: white;
  color: ${theme.colors.font};
`;

export const LineupPopup = ({ id, close }) => {
  return (
    <StyledModal close={close} lockBackground={true}>
      <PopupWrapper>
        <Lineup id={id} showPlayer={false} />
      </PopupWrapper>
    </StyledModal>
  );
};
