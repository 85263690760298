import React from "react";
import { Categories } from "./Categories/Categories";
import { BlogList } from "./BlogList";
import styled from "@emotion/styled";
import MaxWidth from "../Layout/MaxWidth";
import { theme } from "../Theme";
import { Query } from "react-apollo";
import { config } from "../../config";
import routeQuery from "../RouteQuery.gql";
import { PageHeader } from "../Page/Page";
import { Breadcrumbs } from "../Layout/Breadcrumbs/Breadcrumbs";
import { ShutterLink } from "../ui/ShutterLink";

const BlogPageWrapper = styled(MaxWidth)`
  max-width: ${theme.posts.maxWidth};
  .page-header {
    text-align: center;
    padding: 0;
    background: none;
    color: initial;
    h1 {
      margin-top: 1rem;
    }
  }
`;

const BlogPage = ({ ...props }) => {
  const selectedCategory = props.match.params.cat || null;
  return (
    <BlogPageWrapper>
      <Query
        query={routeQuery}
        variables={{
          uri: config.routes.blog.uri,
        }}
      >
        {({ data }) => {
          if (data && data.nodeByUri) {
            const page = data.nodeByUri;
            const breadcrumbsData = selectedCategory
              ? [
                  ...page.seo.breadcrumbs,
                  { url: selectedCategory, text: selectedCategory },
                ]
              : [...page.seo.breadcrumbs];
            return (
              <>
                <Breadcrumbs data={breadcrumbsData} />
                <PageHeader className="page-header">
                  <h1>
                    <ShutterLink to={page.uri}>{page.title}</ShutterLink>
                  </h1>
                  <p dangerouslySetInnerHTML={{ __html: page.content }} />
                </PageHeader>
              </>
            );
          } else return null;
        }}
      </Query>
      <Categories selectedCategory={selectedCategory} />
      <BlogList selectedCategory={selectedCategory} {...props} />
    </BlogPageWrapper>
  );
};

export default BlogPage;
