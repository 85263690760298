import React from "react";
import styled from "@emotion/styled";
import { contentStyles } from "./contentStyles";

const InnerContentWrapper = styled("div")`
  ${contentStyles}
  p {
    margin-bottom: 1rem;
  }
`;

const InnerContent = ({ children }) => {
  return <InnerContentWrapper>{children}</InnerContentWrapper>;
};

export default InnerContent;
