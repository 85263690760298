import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { theme } from "../Theme";
import { Game } from "./Game";
import RightIcon from "../ui/icons/RightIcon";
import { useFetch } from "../Util/Hooks/useFetch";

const FeedWrapper = styled.div`
  position: relative;
`;

const GameControl = styled.div`
  .game-wrapper {
    width: 100%;
    margin: 0 auto;
  }
`;

const NavItem = styled.div`
  cursor: pointer;
  position: absolute;
  top: 100px;
  padding: 0.5rem;
  ${theme.above.md} {
    padding: 1rem;
  }
  svg {
    color: ${theme.colors.white};
    font-size: 2rem;
    &:hover {
    }
    ${theme.above.md} {
      font-size: 3rem;
    }
  }
  &:not(.prev) {
    right: 0;
  }
  &.prev {
    left: 0;
    svg {
      transform: rotate(180deg);
    }
  }
  &.disabled {
    svg {
      color: ${theme.colors.primaryDark};
    }
    cursor: no-drop;
  }
`;

const NEXT_URL = `https://www.thesportsdb.com/api/v1/json/1/eventsnext.php?id=133664`;
const PREV_URL = `https://www.thesportsdb.com/api/v1/json/1/eventslast.php?id=133664`;

const NavControl = ({ index, prev, setCurrent, list }) => {
  const nextIndex = prev ? index - 1 : index + 1;
  const valid = nextIndex >= 0 && nextIndex < list.length;
  return (
    <NavItem
      className={`${!valid && "disabled"} ${prev && "prev"}`}
      onClick={() => {
        if (valid) setCurrent(nextIndex);
      }}
    >
      <RightIcon />
    </NavItem>
  );
};

export const MatchFeed = () => {
  const [currentGame, setCurrent] = useState(5);
  const [list, setList] = useState(false);

  //FETCH
  const nextData = useFetch(NEXT_URL);
  const prevData = useFetch(PREV_URL);

  //MERGE DATA
  useEffect(() => {
    if (nextData.status === "fetched" && prevData.status === "fetched") {
      const prevList = [...prevData.data.results];
      const list = [...prevList.reverse(), ...nextData.data.events];
      setList(list);
    }
  }, [nextData, prevData]);

  if (list && list.length) {
    return (
      <FeedWrapper>
        <GameControl>
          <NavControl
            prev={true}
            index={currentGame}
            setCurrent={setCurrent}
            list={list}
          />
          <Game item={list[currentGame]} />
          <NavControl index={currentGame} setCurrent={setCurrent} list={list} />
        </GameControl>
      </FeedWrapper>
    );
  } else return false;
};
