import { css } from "@emotion/core";
import { getFontSize, theme } from "../Theme";

export const buttons = css`
  button,
  a,
  input {
    :focus {
      outline: none;
    }
  }
  button {
    cursor: pointer;
    padding: 0px;
    border: none;
    font: inherit;
    background: transparent;
    font-family: ${theme.typography.fontFamily};
    font-size: ${theme.typography.fontSize};
    letter-spacing: ${theme.typography.letterSpacing};
  }

  // BUTTON CLASSES
  a.button,
  p.button a {
    display: inline-block;
    line-height: 50px !important;
    height: 50px;
    text-transform: uppercase;
    padding: 0 30px;
    font-weight: ${theme.button.fontWeight};
    text-transform: ${theme.button.textTransform};
    font-family: ${theme.button.fontFamily};
    letter-spacing: ${theme.button.letterSpacing};
    ${getFontSize(theme.button.fontSize)};
    &.margin {
      margin: $button-margin;
    }
    &.right {
      float: right;
    }
  }

  a.button.primary,
  p.button.primary a {
    background: ${theme.button.background};
    color: ${theme.button.color};
  }

  a.button.secondary,
  p.button.secondary a {
    background: ${theme.button.backgroundSecondary};
    color: ${theme.button.colorSecondary};
  }

  a.button.buy,
  p.button.buy a {
    background: ${theme.colors.buy};
    color: ${theme.button.color};
  }

  html a.text-button,
  html p.text-button a {
    display: inline-block;
    text-transform: uppercase;
    font-weight: ${theme.fontWeights.bold};
    span {
      text-decoration: underline;
    }
    &.margin {
      margin: 1rem 0;
    }
    &.right {
      float: right;
    }
    &.border {
      margin-top: 1rem;
      padding-top: 0.5rem;
      border-top: 1px solid ${theme.colors.primary};
      width: 50%;
    }
  }
  .comment-reply-link {
    @include button("secondary");
    margin-top: 15px;
  }
`;
