import { css } from "@emotion/core";

export const content = css`
  .flex-row {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 2rem;
      padding: 0 0.7rem;
    }
  }
`;
