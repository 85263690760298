import React from "react";
import SiteContent from "../../Util/SiteContent/SiteContent";
import styled from "@emotion/styled";
import { theme } from "../../Theme";
import { BlockContent } from "../../Blocks/BlockContent";

const FooterWrapper = styled.div`
  width: 100%;
`;

const FooterContent = styled.div`
  .block-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    > * {
      width: 100%;
      padding: 8% ${theme.general.pagePadding.sm};
      margin: 0 !important;
      &:first-child {
        padding: 8% ${theme.general.pagePadding.sm};
        background: ${theme.footer.background2};
      }
      &:last-child {
        padding: 2rem ${theme.general.pagePadding.sm};
        background: ${theme.footer.background};
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        a,
        li,
        .fal {
          color: ${theme.footer.color2};
        }
      }
    }
    .flex-row {
      width: 100%;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a,
    li,
    .fal {
      color: ${theme.footer.color};
    }
    .wp-block-columns {
      justify-content: center;
    }
    img {
      max-width: 160px;
      margin: 0 auto;
    }
  }
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <SiteContent id="footer">
        {(layout) => (
          <FooterContent>
            <BlockContent content={layout.content} freeWidth={true} />
          </FooterContent>
        )}
      </SiteContent>
    </FooterWrapper>
  );
};

export default Footer;
