import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { theme } from "../../../Theme";
import { ShutterLink } from "../../../ui/ShutterLink";
import Logo from "../Logo";
import { MobileMenu } from "./MobileMenu";
import { PagePadding, PagePaddingWrapper, Inner } from "../../MaxWidth";
import Drawer from "../../../ui/Drawer";
import MenuIcon from "../../../ui/icons/MenuIcon";

const MobileHeaderSpacer = styled.div`
  height: ${theme.mobileHeader.height.s + "px"};
  ${theme.above.lg} {
    display: none;
  }
`;

const MobileHeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  z-index: 100;
  background: white;
  width: 100%;
  top: ${theme.topBar.height.sm + "px"};
  transition: top 0.2s;
  &[data-scroll="true"] {
    top: 0;
  }
  ${theme.above.lg} {
    display: none;
  }
  a {
    text-decoration: none;
    &:hover {
    }
  }
  height: ${theme.mobileHeader.height.s + "px"};
  border-bottom: 1px solid ${theme.colors.border};
  ${PagePaddingWrapper},
  ${Inner} {
    height: 100%;
    width: 100%;
  }
  ${Inner} {
    padding: 10px 0;
    height: 100%;
    position: relative;
    text-align: center;
    svg {
      font-size: 1.6rem;
    }
  }
`;

const SiteLogo = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    display: block;
  }
  img {
    display: block;
    max-width: 200px;
    max-height: 75px;
  }
  &[data-scroll="true"] {
    img {
      max-height: 40px;
    }
  }
`;

const MobileButton = styled.button``;

const AbsoluteList = styled.div`
  position: absolute;
  display: flex;
  left: 0;
  top: 0;
  height: 100%;
  align-items: center;
  > * {
  }
`;

export const MobileHeader = ({ scrolling }) => {
  const [mobileMenu, setMobileMenu] = useState(false);
  return (
    <>
      <MobileHeaderSpacer />
      <MobileHeaderWrapper data-scroll={scrolling}>
        <PagePadding>
          <Inner>
            <AbsoluteList>
              <MobileButton
                onClick={() => {
                  setMobileMenu(true);
                }}
              >
                <MenuIcon />
              </MobileButton>
            </AbsoluteList>
            <SiteLogo data-scroll={scrolling}>
              <ShutterLink to="/">
                <Logo type="Mobile" />
              </ShutterLink>
            </SiteLogo>
          </Inner>
        </PagePadding>
      </MobileHeaderWrapper>
      <Drawer isOpen={mobileMenu} size={500} setOpen={setMobileMenu}>
        <MobileMenu setMobileMenu={setMobileMenu} />
      </Drawer>
    </>
  );
};
