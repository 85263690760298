import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollRestoration() {
  const location = useLocation();
  //const pathname = location.pathname;

  const prevLocationRef = useRef();
  useEffect(() => {
    prevLocationRef.current = location;
  }, [location]);

  //EXCEPTIONS
  if (location && location.search) {
    const urlQuery = new URLSearchParams(location.search);
    const currentPage = urlQuery.get("page");
    const group = urlQuery.get("group");
    const match = urlQuery.get("matchId");
    const player = urlQuery.get("playerId");
    const noscroll = urlQuery.get("noscroll");
    if (group || match || noscroll || player || parseInt(currentPage) > 1)
      return null;
  }

  //SCROLL TO TOP IF NEW ROUTE
  if (prevLocationRef.current && location.key !== prevLocationRef.current.key) {
    window.scrollTo(0, 0);
  }

  if (location) return null;
}
