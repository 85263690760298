import { css } from "@emotion/core";

export const media = css`
  img,
  iframe {
    max-width: 100%;
  }

  img {
    height: auto;
  }
`;
