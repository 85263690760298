import React from "react";
import styled from "@emotion/styled";

const ContentWrapper = styled("main")`
  background: #fff;
  flex: 1 1 auto;
`;

const MainBackground = styled("div")``;

const Content = ({ children }) => {
  return (
    <ContentWrapper>
      <MainBackground>{children}</MainBackground>
    </ContentWrapper>
  );
};

export default Content;
