import React from "react";
import styled from "@emotion/styled";
import { theme } from "../../../Theme";
import Logo from "../Logo";
import { DesktopMenu } from "./DesktopMenu";
import { PagePadding, PagePaddingWrapper, Inner } from "../../MaxWidth";
import { ShutterLink } from "../../../ui/ShutterLink";

const DesktopHeaderSpacer = styled.div`
  height: ${theme.desktopHeader.height.lg + "px"};
  transition: height 0.3s;
  ${theme.only.md} {
    height: ${theme.desktopHeader.height.md + "px"};
  }
  ${theme.below.md} {
    display: none;
  }
`;

const DesktopHeaderWrapper = styled.header`
  position: fixed;
  top: ${theme.topBar.height.lg + "px"};
  transition: top 0.2s;
  z-index: 100;
  background: white;
  width: 100%;
  &[data-scroll="true"] {
    top: 0;
  }
  ${theme.below.md} {
    display: none;
  }
  a {
    text-decoration: none;
    &:hover {
    }
  }
  height: ${theme.desktopHeader.height.lg + "px"};
  border-bottom: 1px solid ${theme.colors.border};
  ${PagePaddingWrapper},
  ${Inner} {
    height: 100%;
    width: 100%;
  }
  ${Inner} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &[data-scroll="true"] {
    height: ${theme.desktopHeader.height.scroll + "px"};
  }
`;

const SiteLogo = styled.div`
  a {
    display: flex;
    align-items: center;
  }
  img {
    max-width: 160px;
    transition: all 0.3s;
  }
  &[data-scroll="true"] {
    img {
      max-width: 60px;
    }
  }
`;

export const DesktopHeader = ({ scrolling }) => {
  return (
    <>
      <DesktopHeaderSpacer data-scroll={scrolling} />
      <DesktopHeaderWrapper data-scroll={scrolling}>
        <PagePadding>
          <Inner>
            <SiteLogo data-scroll={scrolling}>
              <ShutterLink to="/">
                <Logo type="desktop" />
              </ShutterLink>
            </SiteLogo>
            <DesktopMenu scrolling={scrolling} />
          </Inner>
        </PagePadding>
      </DesktopHeaderWrapper>
    </>
  );
};
