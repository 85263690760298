import React, { useState } from "react";
import styled from "@emotion/styled";
import { theme } from "../Theme";
import { dateFormat } from "../Util/Helpers";
import { LineupPopup } from "../Match/Lineup/LineupPopup";
import { Link, useHistory, useLocation } from "react-router-dom";
import { getScorer } from "../Match/helpers";
import { Ball } from "../Match/Lineup/Ball";

const Wrapper = styled.div`
  position: relative;
`;
const MatchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.2rem 0;
  border-top: 1px solid ${theme.colors.border};
  ${theme.below.sm} {
    padding: 1rem 0;
    flex-direction: column;
    div.group-date,
    span.stadium-name {
      text-align: center;
      width: 100%;
      margin: 5px 0;
    }
    span.stadium-name {
      margin-top: 30px;
    }
  }
  .group-date {
    display: flex;
    flex-direction: column;
    .time {
      margin: 4px 0;
    }
    .day {
      margin: 0;
    }
  }
  .group-date,
  .stadium-name {
    width: 15%;
    font-size: 0.8rem;
  }
  .stadium-name {
    text-align: right;
  }
  .teams-wrapper {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    ${theme.below.sm} {
      width: 100%;
    }
  }
  .team {
    display: flex;
    align-items: flex-start;
    width: 40%;
    ${theme.below.sm} {
      flex-direction: column;
      .team-info {
        order: 1;
      }
      &:not(.away) {
        align-items: flex-end;
      }
    }
    &:not(.away) {
      justify-content: flex-end;
      .team-info {
        text-align: right;
      }
    }
    &.away {
      .team-info {
        ${theme.above.md} {
          order: 1;
        }
      }
    }
    .team-info {
      padding: 0.3rem 1rem;
      ${theme.below.sm} {
        padding: 0.3rem 0;
      }
      .name {
        word-break: break-all;
      }
      .events-list {
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        svg {
          font-size: 9px;
          margin-right: 5px;
        }
        span {
          font-size: 0.8rem;
          text-transform: uppercase;
          color: ${theme.colors.greys[5]};
        }
      }
    }
    .name {
      margin: 0;
    }
    img {
      margin: 0 0.5rem;
      ${theme.below.sm} {
        margin: 0;
      }
      width: 30px;
      height: 30px;
    }
  }
  .result {
    padding: 0.3rem 1rem;
    margin: 0 1rem;
    display: flex;
    align-items: center;
    position: relative;
    > a {
      display: flex;
      align-items: center;
    }
    &[data-status="UPCOMING"] {
      .status-value {
        display: none;
      }
    }
    &[data-status="LIVE"] {
      background: #fbd72a;
      .status-value {
        color: ${theme.colors.font};
      }
    }
    &[data-status="UPCOMING"] {
    }
    .status-wrapper {
      position: absolute;
      width: 100%;
      text-align: center;
      top: 32px;
      left: 0;
      font-size: 0.8rem;
      color: ${theme.colors.greys[5]};
      button {
        font-size: 0.8rem;
        color: ${theme.colors.green};
      }
    }
    h5 {
      margin: 0;
    }
    .seperator {
      margin: 0 5px;
    }
  }
`;
export const Team = ({ team, away, events }) => {
  return (
    <div className={`team ${away ? "away" : ""}`}>
      <div className="team-info">
        <h5 className="name">{team.internationalName}</h5>
        {events && (
          <div className="events-list">
            {events.map((event, index) => {
              const name = event.player
                ? event.player.translations.lastName.EN
                : false;
              return (
                <span key={index}>
                  <Ball />
                  {name} {event.goalType === "OWN_GOAL" && "(OG) "}
                  {event.goalType === "PENALTY_GOAL" && "(P) "}
                  {event.time.minute}'
                </span>
              );
            })}
          </div>
        )}
      </div>
      <img src={team.logoUrl} alt={team.internationalName} />
    </div>
  );
};

const Result = ({ match }) => {
  const location = useLocation();
  const history = useHistory();

  //GET OPEN GROUP
  const urlQuery = new URLSearchParams(location.search);
  const currentMatch = parseInt(urlQuery.get("matchId"));

  const time = new Date(match.kickOffTime.dateTime);
  const gameTime = dateFormat(time);
  const [lineup, showLineUp] = useState(
    parseInt(match.id, 10) === currentMatch
  );
  //const lineup = parseInt(match.id, 10) === currentMatch;
  return (
    <div className="result" data-status={match.status}>
      {match.status === "UPCOMING" ? (
        <>
          <h5>{gameTime.time}</h5>
        </>
      ) : (
        <>
          {match.score && (
            <Link to={`/match/${match.id}`}>
              <h5>{match.score.total.home}</h5>
              <span className="seperator">-</span>
              <h5>{match.score.total.away}</h5>
            </Link>
          )}
        </>
      )}
      <div className="status-wrapper">
        <div className="status-value">
          {match.status === "LIVE"
            ? match.minute
              ? match.minute.normal + "'"
              : match.translations.phaseName.EN
            : match.status}
        </div>
        {match.lineupStatus === "TACTICAL_AVAILABLE" &&
          match.status !== "FINISHED!" && (
            <button
              onClick={() => {
                showLineUp(true);
              }}
            >
              LINEUP
            </button>
          )}
      </div>
      {lineup && (
        <LineupPopup
          id={match.id}
          close={() => {
            showLineUp(false);
          }}
        />
      )}
    </div>
  );
};

const MatchRow = ({ match }) => {
  const time = dateFormat(match.kickOffTime.dateTime);
  const homeEvents = match.playerEvents
    ? getScorer(match.playerEvents.scorers, match.homeTeam.id)
    : false;
  const awayEvents = match.playerEvents
    ? getScorer(match.playerEvents.scorers, match.awayTeam.id)
    : false;
  return (
    <MatchWrapper>
      <div className="group-date">
        <span className="group-name">{match.group?.metaData.groupName}</span>
        <span className="date">{time.date}</span>
        <span className="day">{time.day}</span>
      </div>
      <div className="teams-wrapper">
        <Team team={match.homeTeam} events={homeEvents} />
        <Result match={match} />
        <Team team={match.awayTeam} away={true} events={awayEvents} />
      </div>
      <span className="stadium-name">
        {match.stadium.translations?.officialName?.EN}
      </span>
    </MatchWrapper>
  );
};

export const MatchList = ({ matchData }) => {
  return (
    <Wrapper>
      {matchData.map((match, index) => {
        return <MatchRow key={index} match={match} />;
      })}
    </Wrapper>
  );
};
