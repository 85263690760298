import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

export const TeamRow = ({ row }) => {
  return (
    <tr>
      <td className="teamName">
        <img src={row.team.logoUrl} alt="teamLogo" className="teamLogo"></img>
        <p>{row.team.translations.displayOfficialName.EN}</p>
      </td>
      <td className="score">{row.played}</td>
      <td className="score">{row.won}</td>
      <td className="score">{row.drawn}</td>
      <td className="score">{row.lost}</td>
      <td className="label-big score">{row.goalsFor}</td>
      <td className="label-big score">{row.goalsAgainst}</td>
      <td className="label-big score">{row.goalDifference}</td>
      <td className="score">{row.points}</td>
    </tr>
  );
};
