//USING THIS TO HAVE REM LIKE MANAGMENT OF HEADER-SIZES WHICH OFTEN HAVE A DIFFERENT FONT
const remCalc = function (size) {
  if (isNaN(size)) {
    size = parseInt(size);
  }
  return size / typography.fontSize.replace("px", "") + "rem";
};

const createBreakPoints = function (labels, breakpoints, mediaString = false) {
  let breakpointObject = {
    above: {},
    below: {},
    only: {},
  };
  labels.forEach((label, index) => {
    breakpointObject["above"][label] =
      (mediaString ? `@media` : ``) + `(min-width: ${breakpoints[index]})`;
    breakpointObject["below"][label] =
      (mediaString ? `@media` : ``) + `(max-width: ${breakpoints[index + 1]})`;
    breakpointObject["only"][label] =
      (mediaString ? `@media` : ``) +
      `(min-width: ${breakpoints[index]}) and (max-width: ${
        breakpoints[index + 1]
      })`;
  });
  return breakpointObject;
};

const labels = ["xs", "sm", "md", "lg", "xl"];
const breakpoints = ["0px", "400px", "700px", "1100px", "1400px", "2000px"];

export const mediaQueries = createBreakPoints(labels, breakpoints);

const cssMediaQueries = createBreakPoints(labels, breakpoints, true);

export const getFontSize = (props) => {
  return (
    "font-size:" +
    props[0] +
    ";" +
    theme.above.md +
    "{font-size:" +
    props[1] +
    ";}" +
    theme.above.lg +
    "{font-size:" +
    props[2] +
    ";}" +
    theme.above.xl +
    "{font-size:" +
    props[3] +
    ";}"
  );
};

//COLORS VARIABLES
let colors = {
  white: "#FFF",
  black: "#111",
  red: "#E82A2A",
  green: "#69a24c",
  yellow: "#FFCB37",
  font: "#222",
  fontSecondary: "#2D1450",
  primary: "#DB042C",
  primaryLight: "#ff6c87",
  primaryDark: "#92041e",
  secondary: "#0065B1",
  discount: "#b44e3c",
  link: "#ce3c8a",
  border: "#DDD",
  borderLight: "#F1F1F1",
  borderDark: "#333",
  borderInput: "#AAA",
  lightgrey: "#f6f6f6",
  vanillaWhite: "#fbf8f4",
  grey: "#565558",
  error: "#ff3131",
  success: "#69a24c",
  greys: [
    "#F9F9F9",
    "#F1F1F1",
    "#D5D5D5",
    "#C4C4C4",
    "#9A9A9A",
    "#777777",
    "#3B3B3B",
  ],
};

colors.buy = "#EEAE00";
colors.buyDark = "#a57b0a";

//FONTS
const fonts = {
  primary: "Quattrocento Sans",
  secondary: "Oswald",
};

const fontWeights = {
  ultra: 100,
  light: 300,
  normal: 400,
  bold: 700,
};

//TYPOGRAPHY
let typography = {
  fontFamily: fonts.primary,
  fontWeight: fontWeights.normal,
  fontSize: "15px",
  letterSpacing: "1.2px",
  maxWidth: "800px",
  paragraph: {
    fontSize: "1rem",
    lineHeight: "1.7em",
    marginBottom: "1rem",
  },
  header: {
    fontFamily: fonts.secondary,
    textTransform: "uppercase",
    fontStyle: "normal",
    fontWeight: fontWeights.normal,
    letterSpacing: "1px",
    secondFont: {
      fontFamily: fonts.primary,
      fontWeight: fontWeights.normal,
    },
    secondColor: {
      color: colors.primary,
    },
  },
};

//HEADER FONTSIZES h1-h5 (sm & md & lg & xl)
typography.header.fontSize = [
  "__",
  [remCalc(26), remCalc(31), remCalc(41), remCalc(52)],
  [remCalc(20), remCalc(24), remCalc(28), remCalc(31)],
  [remCalc(18), remCalc(20), remCalc(21), remCalc(21)],
  [remCalc(15), remCalc(16), remCalc(17), remCalc(17)],
  [remCalc(14), remCalc(14), remCalc(14), remCalc(14)],
];

const general = {
  maxWidth: "1600px",
  pagePadding: {
    lg: "1rem",
    md: "1rem",
    sm: "1rem",
  },
  hallmarkBorder: "2px solid " + colors.borderDark,
  radius: "3px",
  marginTop: {
    sm: "2%",
    lg: "2%",
  },
};

//BUTTON
const button = {
  background: colors.primary,
  backgroundSecondary: "#777",
  color: colors.white,
  colorSecondary: colors.white,
  fontFamily: fonts.secondary,
  fontWeight: typography.header.fontWeight,
  fontSize: typography.header.fontSize[4],
  ListFontSize: typography.header.fontSize[5],
  textTransform: "uppercase",
  letterSpacing: "1.5px",
};

//TOPBAR
const topBar = {
  height: {
    lg: 40,
    md: 40,
    sm: 40,
    scroll: 0,
  },
  color: colors.white,
  background: colors.secondary,
  fontSize: "0.9rem",
  fontWeight: fontWeights.normal,
  usp: {
    fontSize: remCalc(15),
    fontWeight: fontWeights.normal,
  },
};

//DESKTOP HEADER
const desktopHeader = {
  height: {
    lg: 80,
    md: 80,
    scroll: 70,
  },
  nav: {
    fontSize: remCalc(15),
    padding: "1.5rem",
    barHoverColor: colors.buy,
    fontWeight: fontWeights.normal,
    fontStyle: "normal",
    fontFamily: fonts.secondary,
    textTransform: "uppercase",
    hoverColor: colors.buy,
    activeColor: colors.primaryDark,
    selectedColor: colors.primary,
    subMenu: {
      lv2: {
        fontSize: "1.1rem",
      },
      lv3: {
        fontSize: "0.9rem",
      },
    },
    height: {
      lg: 70,
      md: 70,
      scroll: 70,
    },
    useSubMenuImage: true,
  },
};

//MOBILE HEADER
const mobileHeader = {
  height: {
    s: 60,
    scroll: 60,
  },
  search: {
    height: {
      s: 60,
    },
  },
  logo: {
    height: 36,
  },
  menu: {
    padding: "2rem",
  },
  nav: {
    lv1: {
      fontSize: remCalc(17),
      fontWeight: typography.header.fontWeight,
      fontFamily: typography.header.fontFamily,
      lineHeight: "3.4em",
      textTransform: "uppercase",
      fontStyle: "normal",
    },
    lv2: {
      fontSize: "0.9rem",
      lineHeight: "3.2em",
    },
    lv3: {
      fontSize: "0.8rem",
      lineHeight: "3em",
    },
    hoverColor: colors.primary,
    activeColor: colors.primaryDark,
    selectedColor: colors.primary,
  },
};

//HEADER HEIGHTS
const header = {
  height: {
    lg: topBar.height.lg + desktopHeader.height.lg,
    md: topBar.height.md + desktopHeader.height.md,
    sm: topBar.height.md + mobileHeader.height.sm,
    xs: topBar.height.md + mobileHeader.height.xs,
    scroll: topBar.height.scroll + desktopHeader.height.scroll,
  },
  scrollBreak: 50,
};

//FOOTER
const footer = {
  background: colors.primary,
  background2: colors.secondary,
  color: colors.white,
  color2: colors.white,
};

const content = {
  background: colors.white,
  color: colors.font,
  textPadding: {
    sm: "10%",
    md: "10%",
    lg: "1rem 3rem",
  },
  lightBackground: "#f5f5f5",
  darkBackground: "#333",
  darkColor: colors.white,
};

const blocks = {
  mediaTextPadding: {
    sm: `8% ${general.pagePadding.sm}`,
    md: `8% ${general.pagePadding.md}`,
    lg: `10% 15%`,
  },
  image: {
    padding: {
      sm: `8% ${general.pagePadding.sm}`,
      md: `8% ${general.pagePadding.md}`,
      lg: `10% ${general.pagePadding.lg}`,
    },
    background: "#333",
    backgroundPadding: {
      sm: `15% 20%`,
      md: `15% 20%`,
      lg: `20% 25%`,
    },
  },
  background: "#333",
  backgroundLight: "#F1F1F1",
  text: {
    background: "#333",
    background2: "#555",
    background3: "#444",
    backgroundLight: "#eee",
  },
  gallery: {
    space: 5,
  },
  columns: {
    space: 4,
  },
};

//START PAGE
const startPage = {
  headers: {
    textTransform: "uppercase",
    fontSize: [
      "__",
      [remCalc(41), remCalc(62), remCalc(82), remCalc(95)],
      typography.header.fontSize[1],
      typography.header.fontSize[2],
      typography.header.fontSize[3],
      typography.header.fontSize[4],
    ],
  },
  headerRow: {
    background: colors.primary,
    color: colors.white,
    backgroundSecondary: colors.secondary,
    colorSecondary: colors.white,
  },
  iconBar: {
    background: colors.secondary,
    color: colors.white,
  },
  textBox: {
    background: colors.primary,
    color: colors.font,
  },
  readMore: {
    color: colors.font,
    fontFamily: typography.header.fontFamily,
    fontWeight: typography.header.fontWeight,
  },
  cta: {
    fontSize: button.fontSize,
    color: colors.white,
    background: "transparent",
    border: "4px solid " + colors.buy,
    fontFamily: button.fontFamily,
    fontWeight: button.fontWeight,
    textTransform: "none",
    hover: {
      color: colors.font,
      background: colors.buy,
      border: "4px solid " + colors.buy,
    },
    secondary: {
      color: colors.font,
      background: colors.buy,
      border: "4px solid " + colors.buy,
      hover: {
        color: colors.white,
        background: "transparent",
        border: "4px solid " + colors.buy,
      },
    },
  },
};

const posts = {
  maxWidth: "1000px",
};

//EXPORT COMPLETE THEME
export const theme = {
  ...cssMediaQueries,
  fonts,
  fontWeights,
  typography,
  colors,
  general,
  button,
  topBar,
  desktopHeader,
  mobileHeader,
  header,
  footer,
  content,
  blocks,
  startPage,
  posts,
};
